import React, { useState } from "react";
import { IonIcon, IonItem, IonLabel, useIonToast } from '@ionic/react';
import { star, starHalfOutline } from 'ionicons/icons';
import './StarRating.css';

interface ContainerProps {
    onSetRating?: any;
    id: string;
    name?: string;
    initalstars: number;
    disabled?: boolean;
    disabledmsg?: string;
}

const StarRating: React.FC<ContainerProps> = ({ onSetRating, id, name, initalstars, disabled, disabledmsg }) => {
    const [starcount, setStarCount] = useState(initalstars);
    const [presentDisabledToast] = useIonToast();

    const handleStarChange = (rating: number) => {
        if (!disabled) {
            setStarCount(rating);
            if (onSetRating) onSetRating(id, rating);
        }
        else {
            if (disabledmsg) {
                presentDisabledToast({
                    message: disabledmsg,
                    duration: 3000,
                    position: 'top',
                    color: 'primary',
                })
            }
        }
    }

    const fullStar = (scount: number, snumber: number): any => {
        let scicon;
        const remainder = scount % 1;
        if (scount >= snumber || starcount + 1 < snumber)
            scicon = star;
        else if (remainder === 0)
            scicon = star;
        else
            scicon = (remainder < 0.25 || remainder > 0.75) ? star : starHalfOutline;
        return scicon
    }

    return (
        <IonItem lines="none" class="centered">
            {name && <IonLabel>{name}</IonLabel>}
            <IonIcon key={1} onClick={() => handleStarChange(1)} icon={fullStar(starcount, 1)} class={starcount > 0 ? 'star-on' : 'star-off'} />
            <IonIcon key={2} onClick={() => handleStarChange(2)} icon={fullStar(starcount, 2)} class={starcount >= 1.25 ? 'star-on' : 'star-off'} />
            <IonIcon key={3} onClick={() => handleStarChange(3)} icon={fullStar(starcount, 3)} class={starcount >= 2.25 ? 'star-on' : 'star-off'} />
            <IonIcon key={4} onClick={() => handleStarChange(4)} icon={fullStar(starcount, 4)} class={starcount >= 3.25 ? 'star-on' : 'star-off'} />
            <IonIcon key={5} onClick={() => handleStarChange(5)} icon={fullStar(starcount, 5)} class={starcount >= 4.25 ? 'star-on' : 'star-off'} />
        </IonItem>
    )
}

export default StarRating;