import React, { useContext, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import firebase from '../firebase';
import { storeContext } from '../context/store';
import { useCookies } from "react-cookie";

interface StarNavProps extends RouteComponentProps<{
    navlink: string;
}> { }

const StarNav: React.FC<StarNavProps> = ({ match }) => {
    const { dispatch } = useContext(storeContext);
    const [cookies, setCookie] = useCookies(['hs-path']);

    const history = useHistory();
    let navlinks = match.params.navlink.split("cl-");
    useEffect(() => {
        firebase.auth().signInAnonymously()
            .then(() => {
                let memberrec: any;
                let companyrec: any;
                let clientrec: any;
                let memberRef = firebase.database().ref(`/member/${navlinks[0]}`).orderByChild("lastname");
                memberRef.once('value', (snapshot) => {
                    memberrec = snapshot.val();
                    memberrec.id = snapshot.key;
                }).then(() => {
                    let companyRef = firebase.database().ref(`company/${memberrec.companyid}`);
                    companyRef.once('value', (snapshot) => {
                        companyrec = snapshot.val();
                        memberrec.companyname = companyrec.name;
                        memberrec.logo = companyrec.logo;
                        memberrec.address = companyrec.address;
                        memberrec.city = companyrec.city;
                        memberrec.state = companyrec.state;
                        memberrec.postalcode = companyrec.postalcode;
                        //  if (memberrec.officeid.length === 0) {} set address city state postalcode to office

                        if (companyrec.starcolor)
                            document.body.style.setProperty('--hs-star-color', companyrec.starcolor);
                        if (companyrec.primarycolor) {
                            document.body.style.setProperty('--ion-color-primary', companyrec.primarycolor);
                            document.body.style.setProperty('--ion-color-primary-shade', companyrec.secondarycolor);
                        }
                    }).then(() => {
                        let clientRef = firebase.database().ref(`client/${navlinks[0]}/cl-${navlinks[1]}`);
                        clientRef.once('value', (snapshot) => {
                            clientrec = snapshot.val();
                            if (clientrec) {
                                dispatch({
                                    type: 'SET_TOURS', payload: {
                                        member: memberrec,
                                        clients: [{
                                            id: "cl-" + navlinks[1],
                                            firstname: clientrec.firstname,
                                            lastname: clientrec.lastname,
                                            email: clientrec.email,
                                            phone: clientrec.phone,
                                            agentid: '',
                                            shortlink: '',
                                            budget: clientrec.budget,
                                            active: clientrec.active
                                        }],
                                        clientmode: true,
                                        signedin: true
                                    }
                                });
                                let expires = new Date();
                                expires.setDate(expires.getDate() + 365)
                                setCookie("hs-path", match.params.navlink, { expires: expires, path: '/' });
                                history.push("/clientintro");
                            }
                            else {
                                history.push("/signin");
                            }
                        })
                    })
                })

            })
            .catch((error) => {
            });

    }, []);
    return (
        null
    );
}

export default StarNav;