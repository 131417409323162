
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import CookieConsent from "react-cookie-consent"
import { star, settings, homeOutline, person } from 'ionicons/icons';
import Tours from './pages/tours';
import Overall from './pages/overall';

import Contact from './pages/contact'
import Settings from './pages/settings'
import ClientIntro from './pages/clientIntro'
//import Rm from './pages/rm';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import NTCalendar from './pages/ntCalendar';
import NTClients from './pages/ntClients';
import NTProperties from './pages/ntProperties';
import NTRoute from './pages/ntRoute';
import StarNav from './pages/starnav';
import Signin from './pages/signin';
import Register from './pages/register';
import Invite from './pages/invite';
import { useContext } from 'react';
import { storeContext } from './context/store';
import EditTour from './pages/edittour';
import TourReview from './pages/tourreview';
import { useCookies } from "react-cookie";
import "default-passive-events";

const App: React.FC = () => {
  const { state } = useContext(storeContext);
  const [cookies] = useCookies(["hs-path"]);
  const isAuthenticated = () => {
    let authpath = "";
    if (state.member.id) {
      authpath = "";
    }
    else {
      if (!cookies || (cookies['hs-path'] && !cookies['hs-path'].includes("cl-"))) {
        authpath = "signin";
      }
      else {
        authpath = `/starnav/${cookies['hs-path']}`;
      }
    }

    return authpath;
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/tours"
              render={() => {
                let authpath: string = isAuthenticated();
                return authpath.length === 0 ? <Tours /> : window.location.href = authpath;
              }}
            >
            </Route>
            <Route exact path="/overall"
              render={() => {
                let authpath: string = isAuthenticated();
                return authpath.length === 0 ? <Overall /> : window.location.href = authpath;
              }}
            >
            </Route>

            <Route exact path="/Contact"
              render={() => {
                let authpath: string = isAuthenticated();
                return authpath.length === 0 ? <Contact /> : window.location.href = authpath;
              }}
            >
            </Route>
            <Route exact path="/Settings"
              render={() => {
                let authpath: string = isAuthenticated();
                return authpath.length === 0 ? <Settings /> : window.location.href = authpath;
              }}
            >
            </Route>
            <Route exact path="/clientintro"
              render={() => {
                let authpath: string = isAuthenticated();
                return authpath.length === 0 ? <ClientIntro /> : window.location.href = authpath;
              }}
            >
            </Route>
            <Route exact path="/register"
              render={() => {
                let authpath: string = isAuthenticated();
                return authpath.length === 0 ? <Register /> : window.location.href = authpath;
              }}
            >
            </Route>
            <Route exact path="/">
              <Redirect to="/signin" />
            </Route>
            <Route path={`/ntCalendar/:member`} component={NTCalendar} />
            <Route path={`/ntClients/:member/:tourdate`} component={NTClients} />
            <Route path={`/ntProperties/:member/:client/:tourdate`} component={NTProperties} />
            <Route path={`/ntRoute/:member/:client/:tourdate`} component={NTRoute} />
            <Route path={`/edittour/:member/:client/:tourdate`} component={EditTour} />
            <Route path={`/tourreview/:member/:client/:tourdate`} component={TourReview} />
            <Route path={`/invite/:clientid`} component={Invite} />
            <Route path={`/starnav/:navlink`} component={StarNav} />
            <Route path={`/signin`} component={Signin} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom" id="hsTabBar">
            <IonTabButton tab="tours" href="/tours" disabled={!state.signedin}>
              <IonIcon icon={homeOutline} />
              <IonLabel>Tours</IonLabel>
            </IonTabButton>
            <IonTabButton tab="overall" href="/overall" disabled={!state.signedin}>
              <IonIcon icon={star} />
              <IonLabel>Rate</IonLabel>
            </IonTabButton>
            <IonTabButton tab="contact" href="/contact" disabled={!state.signedin}>
              <IonIcon icon={person} />
              <IonLabel>{state.clientmode ? "Contact" : "Contacts"}</IonLabel>
            </IonTabButton>
            {!state.clientmode &&
              <IonTabButton tab="settings" href="/settings" disabled={!state.signedin}>
                <IonIcon icon={settings} />
                <IonLabel>Settings</IonLabel>
              </IonTabButton>
            }
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
      <CookieConsent buttonText="Got It!" style={{ background: "#000000", fontSize: "15px" }} buttonStyle={{ background: "#000000", color: "white", border: "1px #ffffff solid", fontSize: "larger" }}>By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.</CookieConsent>
    </IonApp>
  )
};

export default App;
