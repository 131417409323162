import { IonBackButton, IonButtons, IonContent, IonGrid, IonHeader, IonLabel, IonListHeader, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState, useContext } from "react";
import { storeContext } from '../context/store';
import { Calendar, DayValue } from "react-modern-calendar-datepicker";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { formatTourDate } from '../global/helperf'
import "react-modern-calendar-datepicker/lib/DatePicker.css";

interface CalendarPageProps extends RouteComponentProps<{
    member: string;
}> { }

const NTCalendar: React.FC<CalendarPageProps> = ({ match }) => {
    const { state } = useContext(storeContext)

    const defdate = new Date();
    const defaultValue: DayValue = {
        year: defdate.getFullYear(),
        month: defdate.getMonth() + 1,
        day: defdate.getDate(),
    };

    const [day, setDay] = useState<DayValue>(defaultValue);
    const history = useHistory();

    const setmyday = (newdate: any) => {
        setDay(newdate);
        const tourdate = formatTourDate(newdate);
        history.push(`/ntClients/${match.params.member}/${tourdate}`);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle class="capit"></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonListHeader>
                    <IonLabel class="labelTitle">
                        First, Select The Tour Date
                        </IonLabel>
                </IonListHeader>
                <Calendar value={day} onChange={setmyday} colorPrimary={state.member.primarycolor} />
                <IonGrid>
                    <IonRow>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default NTCalendar;
