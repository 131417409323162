import React, { useContext, useState, Fragment } from "react";
import { IonItem, IonGrid, IonRow, IonCol, IonLabel, IonText, IonIcon } from '@ionic/react';
import { caretUpCircleOutline, caretDownCircleOutline } from "ionicons/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { storeContext } from '../context/store';
import StarRating from "./StarRating";
import { findClient, tourdate } from '../global/helperf'
import './TimelineBody.css'

interface ContainerProps {
    clienttours: any[];
}

const ClientTours: React.FC<ContainerProps> = ({ clienttours }) => {
    const { state, dispatch } = useContext(storeContext)
    const [showDetails, setDetails] = useState(state.clientmode);
    let proparray: any[] = [];
    const buttonHandler = () => {
        setDetails(current => !current)
    }

    clienttours.forEach(tour => {
        let tmparray = tour.properties;
        tmparray.forEach((property: any) => property.tourDate = tour.date)
        proparray = proparray.concat(tmparray)
    })
    proparray = proparray.sort((a: any, b: any) => b.stars - a.stars)
    const client = findClient(state.clients, clienttours[0].key);

    const propertyList =
        <Fragment>
            <IonGrid class="gridshadow">
                {proparray.map(property => {
                    return (
                        <IonRow key={`ctr-${property.key}`} id={`ct-${property.key}`} class="cprops">
                            <IonCol>
                                <IonItem class="no-min-height" lines="none">{+property.stars.toFixed(2)} Stars</IonItem>
                                <StarRating id={property.key} initalstars={property.stars} disabled={true} key={`cts-${property.key}-${property.stars}`} />
                                <IonItem class="fs-8rem no-min-height pt-5" lines="none">viewed {tourdate(property.tourDate)}</IonItem>
                            </IonCol>
                            <IonCol>
                                <IonLabel class="paddress">
                                    {property.address}<br></br>
                                    {property.city} {property.state}<br></br>
                                    <IonLabel class="tlIconLabel">Full Listing</IonLabel><a href={property.listingurl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} href={property.listingurl} className="iconpad1" /></a>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                    )

                })
                }
            </IonGrid>
        </Fragment>
    return (
        <Fragment>
            {!state.clientmode &&
                <IonItem key={`fct-${client.key}`} lines="none" id={client.key} class="tourkey">
                    <IonIcon size="large" color={showDetails ? "primary-shade" : "primary"} icon={showDetails ? caretUpCircleOutline : caretDownCircleOutline} onClick={buttonHandler}></IonIcon><IonText onClick={buttonHandler} class="tlDate">{client.firstname} {client.lastname}</IonText>
                </IonItem>
            }
            {showDetails && propertyList}
        </Fragment>
    )
}

export default ClientTours;