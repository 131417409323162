import {IonAlert, IonBackButton, IonButtons, IonCol, IonIcon, IonContent, IonDatetime, IonGrid, IonHeader, IonLabel, IonListHeader, IonPage, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToast, IonToolbar, IonButton, IonList, IonItem} from "@ionic/react";
import React, {useState, useEffect, useContext, Fragment} from "react";
import {RouteComponentProps, useLocation, useHistory} from "react-router-dom";
import firebase from '../firebase';
import {storeContext} from '../context/store';
import {Calendar, DayValue} from "react-modern-calendar-datepicker";
import TourRoute from '../components/TourRoute'
import AddProperties from '../components/AddProperties';
import {formatTourDate, hideTabBar, showTabBar} from '../global/helperf'
import {trashOutline, addCircle} from 'ionicons/icons';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./edittour.css";

interface EditTourProps extends RouteComponentProps<{
    member: string;
    client: string;
    tourdate: string;
}> {}

const EditTour: React.FC<EditTourProps> = ({match}) => {
    const location = useLocation<any>();
    const history = useHistory();
    const {state, dispatch} = useContext(storeContext);

    const defaultdate = match.params.tourdate.split("-");
    const [activeTab, setActiveTab] = useState("date");
    const [confirmToast, setConfirmToast] = useState(false);
    const [confirmDeleteToast, setConfirmDeleteToast] = useState(false);
    const [confirmDeletePropertyToast, setConfirmDeletePropertyToast] = useState(false);
    const [propertyToDelete, setPropertyToDelete] = useState("");
    const [displayAddProperties, setDisplayAddProperties] = useState(false);
    const [showPurchaseSubscription, setShowPurchaseSubscription] = useState(false);

    const [day, setDay] = useState<DayValue>({
        year: +defaultdate[0],
        month: +defaultdate[1],
        day: +defaultdate[2],
    });
    const [properties, setProperties] = useState(location.state);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDeleteConfirm, setShowDeleteTourConfirm] = useState(false);
    const [showDeletePropertyConfirm, setShowDeletePropertyConfirm] = useState(false);
    const [confirmAddPropertyToast, setConfirmAddPropertyToast] = useState(false);
    const [defaultDayValue, setDefaultDayValue] = useState<DayValue>({
        year: +defaultdate[0],
        month: +defaultdate[1],
        day: +defaultdate[2],
    })

    useEffect(() => {
        hideTabBar();
        return () => {
            showTabBar();
        };
    });

    const confirmDate = (newdate: any) => {
        setDay(newdate);
        setShowConfirm(true);
    }

    const setDate = () => {
        if (defaultDayValue?.day !== day?.day || defaultDayValue?.month !== day?.month || defaultDayValue?.year !== day?.year)
            changeTourDate();
    }

    const deleteTour = () => {
        if (state.member.subscriptionlevel === 0) {
            setShowPurchaseSubscription(true)
            setShowDeleteTourConfirm(false)
        }
        else {
            let delRef = firebase.database().ref(`tour/${match.params.member}/${match.params.client}/${formatTourDate(day)}/`);
            delRef.remove().then(() => {
                setConfirmDeleteToast(true);

            })
        }
    }

    const purchaseSubscription = () => {
        window.open('https://buy.stripe.com/dR68yOh0g6k2evCfYZ');
    }

    const hideAddNewProperty = () => {
        setDisplayAddProperties(false);
    }

    const deletePropertyConfirm = (pkey: string) => {
        setPropertyToDelete(pkey)
        setShowDeletePropertyConfirm(true);
    }

    const deleteProperty = () => {
        if (propertyToDelete.length > 1) {
            let delRef = firebase.database().ref(`tour/${match.params.member}/${match.params.client}/${formatTourDate(day)}/properties/${propertyToDelete}`);
            delRef.remove().then(() => {
                let cproperties = properties;
                var index = cproperties.findIndex((p: any) => p.key === propertyToDelete);
                cproperties.splice(index, 1);
                setProperties(cproperties);
                setConfirmDeletePropertyToast(true);
            })
        }
    }

    const saveNewTime = (newtime: string, property: any) => {
        const tmparr = newtime.split('T');
        const hoursminutes = tmparr[1].split(':');
        const hhmm = parseInt(hoursminutes[0] + hoursminutes[1]);
        if (newtime.includes("T")) {
            let textRef = firebase.database().ref(`tour/${match.params.member}/${match.params.client}/${match.params.tourdate}/properties/${property.key}/`);
            textRef.update({scheduledtime: hhmm}).then(() => {
                property.scheduledtime = hhmm;
                //clone properties array; find property by key and splice (replace) in property with new time
                const clonedproperties: any = [];
                properties.forEach((val: any) => {
                    clonedproperties.push(Object.assign({}, val))
                });
                const idx = clonedproperties.findIndex((x: any) => x.key === property.key);
                clonedproperties[idx].scheduledtime = property.scheduledtime;
                clonedproperties.sort((a: any, b: any) => (a.scheduledtime > b.scheduledtime) ? 1 : -1);
                setProperties(clonedproperties);
            }).catch((err: any) => console.log(err));
        }
    }

    const changeTourDate = () => {
        let oldRef = firebase.database().ref(`tour/${match.params.member}/${match.params.client}/${formatTourDate(defaultDayValue)}/`);
        let newRef = firebase.database().ref(`tour/${match.params.member}/${match.params.client}/${formatTourDate(day)}/`);
        oldRef.once('value', function (snap: any) {
            let moveTour = snap.val();
            moveTour.date = formatTourDate(day)
            newRef.set(moveTour, function (error: any) {
                if (!error) {
                    oldRef.remove()
                        .then(() => {
                            newRef.update({date: formatTourDate(day)})
                        })
                        .then(() => {
                            setDefaultDayValue(day);
                            if (state.path) {
                                const urlFragments = state.path.split("/");
                                if (urlFragments[1] === match.params.member && urlFragments[2] === match.params.client)
                                    urlFragments[3] = formatTourDate(day);
                                dispatch({
                                    type: 'UPDATE_PATH', payload: {
                                        path: urlFragments.join("/"),
                                    }
                                });
                            }
                            setConfirmToast(true);
                            match.params.tourdate = formatTourDate(day);
                        })
                }
                else if (typeof (console) !== 'undefined' && console.error) {console.error(error);}
            });
        })
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../tours/" />
                    </IonButtons>
                    <IonTitle class="capit"></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonListHeader>
                    <IonLabel class="labelTitle">
                        Edit Tour
                    </IonLabel>
                    <IonButton ion-button icon-only item-right onClick={() => setShowDeleteTourConfirm(true)}>
                        <IonIcon class="col-right pad-can" float-right icon={trashOutline} />
                    </IonButton>
                </IonListHeader>
                <IonSegment value={activeTab}>
                    <IonSegmentButton value="date" onClick={() => {setActiveTab("date")}}>
                        <IonLabel>Date</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="properties" onClick={() => {setActiveTab("properties")}}>
                        <IonLabel>Homes</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="route" onClick={() => {setActiveTab("route")}}>
                        <IonLabel>Route</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {activeTab === "date" &&
                    <Calendar value={day} onChange={confirmDate} />

                }
                {activeTab === "properties" &&
                    <IonGrid>
                        <IonRow>
                            <IonCol size="1" class="pullright" align-self-end>
                                <IonIcon icon={addCircle} onClick={() => setDisplayAddProperties(true)} color="primary" class="addlarge"></IonIcon>
                            </IonCol>
                        </IonRow>
                        {
                            <IonGrid class="zeropad">
                                <IonRow>
                                    {!displayAddProperties &&
                                        <IonCol class="zeropad">
                                            <IonList>
                                                {properties.map((property: any) => {
                                                    let ptime: number = property.scheduledtime;
                                                    let timestr = ptime.toLocaleString('en-US', {
                                                        minimumIntegerDigits: 4,
                                                        useGrouping: false
                                                    })
                                                    timestr = [timestr.slice(0, 2), ":", timestr.slice(2)].join('');
                                                    timestr = `2020-01-01T${timestr}`;
                                                    return (
                                                        <IonItem key={property.key} id={property.key} class="listpad">
                                                            <IonCol size-lg="5" size="10">
                                                                <IonLabel class="pb-10">
                                                                    {property.address}<br></br>
                                                                    {property.city}, {property.state}
                                                                </IonLabel>
                                                                <IonDatetime onIonChange={e => saveNewTime(e.detail.value!, property)} defaultValue={`2021-01-01T19:00`} display-format="h:mm A" picker-format="h:mm A" value={timestr} minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"></IonDatetime>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonIcon class="propertyCan" onClick={() => deletePropertyConfirm(property.key)} float-right icon={trashOutline} />
                                                            </IonCol>
                                                        </IonItem>
                                                    )
                                                })
                                                }
                                            </IonList>
                                        </IonCol>}
                                </IonRow>
                                {displayAddProperties &&
                                    <Fragment>
                                        <IonRow>
                                            <IonCol offset="2" size="10" class="labelTitle">
                                                Add a Property
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol>
                                                <AddProperties setProperty={setProperties} toggleDisplay={hideAddNewProperty} setConfirmToast={setConfirmToast} memberid={match.params.member} clientid={match.params.client} tourdate={match.params.tourdate} currentproperties={location.state} displaySetTime={true} displayCancel={true}></AddProperties>
                                            </IonCol>
                                        </IonRow>
                                    </Fragment>
                                }
                            </IonGrid>
                        }
                    </IonGrid>
                }
                {activeTab === "route" &&
                    <TourRoute setProperties={setProperties} properties={properties} path={`tour/${match.params.member}/${match.params.client}/${match.params.tourdate}/properties`}></TourRoute>
                }

            </IonContent>
            <IonAlert
                isOpen={showConfirm}
                onDidDismiss={() => setShowConfirm(false)}
                header="Confirm the Date Change"
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            setDay(defaultDayValue);
                        }
                    },
                    {
                        text: 'Confirm',
                        handler: () => {
                            setDate();
                        }
                    }
                ]}
            ></IonAlert>

            <IonAlert
                isOpen={showDeleteConfirm}
                onDidDismiss={() => setShowDeleteTourConfirm(false)}
                header="Please Confirm you want to delete this entire tour."
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            ;
                        }
                    },
                    {
                        text: 'Confirm',
                        handler: () => {
                            deleteTour();
                        }
                    }
                ]}
            ></IonAlert>

            <IonAlert
                isOpen={showDeletePropertyConfirm}
                onDidDismiss={() => setShowDeletePropertyConfirm(false)}
                header="Please Confirm you want to delete this home."
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            ;
                        }
                    },
                    {
                        text: 'Confirm',
                        handler: () => {
                            deleteProperty();
                        }
                    }
                ]}
            ></IonAlert>

            <IonAlert
                isOpen={showPurchaseSubscription}
                onDidDismiss={() => setShowPurchaseSubscription(false)}
                header="A free subscription provides you with one tour.  For unlimited tours, get a subscription today."
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            ;
                        }
                    },
                    {
                        text: 'Get a Subscription',
                        handler: () => {
                            purchaseSubscription();
                        }
                    }
                ]}
            ></IonAlert>

            <IonToast
                isOpen={confirmDeleteToast}
                onDidDismiss={() => {
                    setConfirmDeleteToast(false);
                    history.goBack();
                }}
                message="Your Tour was deleted."
                position="top"
                duration={1200}
                color="dark"
            />

            <IonToast
                isOpen={confirmDeletePropertyToast}
                onDidDismiss={() => {
                    setConfirmDeletePropertyToast(false);
                }}
                message="The Home was deleted."
                position="top"
                duration={1200}
                color="dark"
            />
            <IonToast
                isOpen={confirmAddPropertyToast}
                onDidDismiss={() => {
                    setConfirmAddPropertyToast(false);
                }}
                message="The Home was added."
                position="top"
                duration={1200}
                color="dark"
            />

            <IonToast
                isOpen={confirmToast}
                onDidDismiss={() => setConfirmToast(false)}
                message="Great, your Tour was updated."
                position="top"
                duration={1400}
                color="dark"
            />
        </IonPage >
    );
}

export default EditTour;