import React from "react";
import {Tour} from "react-shepherd";
interface ContainerProps {
    tContext: Tour | null;
    displayProductTour: boolean;
}
var displayed = false;
const Start: React.FC<ContainerProps> = ({tContext, displayProductTour}) => {

    if (displayProductTour && !displayed) {
        tContext?.start();
        displayed = true
    }
    return null;
}

export default Start;