import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from '../firebase';
import { IonCard, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonRow, IonText, IonTextarea } from "@ionic/react";
import { caretUpCircleOutline, caretDownCircleOutline, mapOutline, peopleOutline, createOutline } from "ionicons/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBath, faBed, faCar, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import StarRating from "./StarRating";
import { storeContext } from '../context/store';
import { plural, findClient, getFormattedTime, tourdate, isoToMDY, geoLocate } from '../global/helperf'
import './TimelineBody.css'
// import { createPDF } from "../global/pdf-lib/pdf";

interface ContainerProps {
    tourkey: string,
    startdate: string,
    properties: any[];
}

const TimeLineBody: React.FC<ContainerProps> = ({ tourkey, startdate, properties }) => {
    const { state, dispatch } = useContext(storeContext)
    const [showDetails, setDetails] = useState(false);

    const client = findClient(state.clients, tourkey);
    const buttonHandler = () => {
        setDetails(current => !current)
    }

    const history = useHistory();
    const handleSelectProperty = (property: any) => {
        dispatch({
            type: 'SET_PROPERTY', payload: {
                value: property,
                tourid: tourkey.toString(),
                propertyid: property.key,
                path: `tour/${state.member.id}/${client.id}/${startdate}/properties/${property.key}/`,
                clientid: client.id,
            }
        });
        history.push('/overall');
    }

    const handleEditProperty = (selectedproperties: any, tkey: string, sdate: string) => {
        history.push(
            {
                pathname: `/edittour/${tkey}/${sdate}`,
                state: selectedproperties
            })
    }

    const handleDirections = async (address: string, city: string, state: string) => {
        const position = await geoLocate();
        if (position.lat !== 0.0)
            window.open(`https://www.google.com/maps/dir/${position.lat},${position.lng}/${address},${city},${state}?hl=en`);
        else
            alert("You have blocked access to the location service for HomeStar.  Please reset the location permission so that HomeStar can access your location.")
    }

    const handleTourRoute = () => {
        //        e.preventDefault();
        const tourroute = Object.keys(properties).map((key: any) => `/${properties[key].address},${properties[key].city},${properties[key].state}`
        ).join('');
        window.open(`https://www.google.com/maps/dir${tourroute}?hl=en`)
    }

    const saveNotes = (event: any) => {
        const tnotes = (event.target as HTMLInputElement);
        const notes = (event.target as HTMLInputElement).value;
        const path = `tour/${state.member.id}/${client.id}/${startdate}/properties/${tnotes.name}`;
        let textRef = firebase.database().ref(path);
        textRef.update({ agentnotes: notes }).then(() => null).catch((err: any) => console.log(err));
    }

    const handleInvite = (clientid: string) => {
        history.push(
            {
                pathname: `/invite/${clientid}`,
                state: { properties: properties, date: startdate }
            })
    }

    const propertyList =
        <Fragment>
            <IonGrid class="gridshadow">
                {properties.map(property => {
                    return (
                        <IonCard key={property.key} class="pcard">
                            <IonRow id={property.key}>
                                <IonCol size-lg="2" size="5" onClick={() => handleSelectProperty(property)}>
                                    <IonImg class="dashImage" src={property.primaryphoto} onClick={() => handleSelectProperty(property)} />
                                    <StarRating id={property.key} initalstars={property.stars} disabled={true} key={`ps-${property.key}-${property.stars}`} />
                                </IonCol>
                                <IonCol size-lg="10" size="7" class="datacol">
                                    <IonLabel class="tlTime">{getFormattedTime(property.scheduledtime)}</IonLabel><FontAwesomeIcon icon={faCar} className="iconpad" onClick={() => handleDirections(property.address, property.city, property.state)} />
                                    <p className="halfp"><IonLabel>{property.address}</IonLabel><br />
                                        <IonLabel>{property.city}, {property.state} </IonLabel></p>
                                    {(property.beds > 0) && <Fragment><FontAwesomeIcon icon={faBed} className="iconpad" /><IonLabel class="iconlabel">{property.beds}</IonLabel></Fragment>}
                                    {(property.beds > 0) && <Fragment><FontAwesomeIcon icon={faBath} className="iconpad" /><IonLabel class="iconlabel">{property.baths}</IonLabel><br /></Fragment>}
                                    {(property.listingurl && property.listingurl.length > 0) && <Fragment>
                                        <IonLabel class="tlIconLabel">Full Listing</IonLabel><a href={property.listingurl} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} href={property.listingurl} className="iconpad1" /></a>
                                    </Fragment>}
                                    {(property.offerduedate && property.offerduedate.length > 0) && <Fragment>
                                        <p className="halfp" /><IonLabel class="tlIconLabel">Offer Due Date {isoToMDY(property.offerduedate)}</IonLabel>
                                    </Fragment>}
                                </IonCol>
                            </IonRow>
                            {(!state.clientmode) && <IonRow>
                                <IonCol size="12">
                                    <IonTextarea key={`an-${property.key}`} name={property.key} value={property.agentnotes} onBlur={saveNotes} autoGrow={true} placeholder="enter private notes... " class="inpbox" rows={1}></IonTextarea>
                                </IonCol>
                            </IonRow>}
                        </IonCard>
                    )
                })
                }
            </IonGrid>
        </Fragment>
    return (
        <Fragment>
            <IonItem key={tourkey} lines="none" id={tourkey} class="tourkey">
                <IonIcon size="large" color={showDetails ? "primary-shade" : "primary"} icon={showDetails ? caretUpCircleOutline : caretDownCircleOutline} onClick={buttonHandler}></IonIcon><IonText onClick={buttonHandler} class="tlDate">{tourdate(startdate)}</IonText>
                <IonText onClick={buttonHandler} class="tlDate">{Object.keys(properties).length} {` Home${plural(Object.keys(properties).length)}`}</IonText>
                <IonIcon icon={mapOutline} onClick={() => handleTourRoute()}></IonIcon>
                {(!state.clientmode) && <IonIcon slot="end" icon={createOutline} title="Edit" aria-label="Edit" onClick={() => handleEditProperty(properties, tourkey, startdate)}></IonIcon>}
            </IonItem>
            {(!state.clientmode) &&
                <IonItem key={`inv-${tourkey}`} lines="full" ion-text-right align-self-start>
                    <IonCol></IonCol>
                    <IonCol><IonLabel onClick={() => handleInvite(client.id)} class="fright"><IonIcon icon={peopleOutline} onClick={() => handleInvite(client.id)} className="iconpad"></IonIcon> invite {client.firstname} {client.lastname}</IonLabel></IonCol>
                    <IonCol></IonCol>
                </IonItem>
            }
            {showDetails && propertyList}
        </Fragment>
    )
}

export default TimeLineBody;