import React, {useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {storeContext} from '../context/store';
import {useCookies} from "react-cookie";
import './settings.css';

const Settings: React.FC = () => {
    const {state} = useContext(storeContext);
    const [cookies] = useCookies(["hs-path"]);
    //    const [editMode, setEditMode] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!state.member.id) {
            if (cookies && cookies['hs-path'])
                window.location.href = `/starn  av/${cookies['hs-path']}`;
            else
                window.location.href = '/';
        }
    }, []);

    const signout = () => {
        const auth = firebase.auth();
        auth.signOut().then(() => {
            history.go(0);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Settings</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >
                <IonGrid>
                    <IonRow class="centerRow">
                        <IonCol offset="1" size="8">
                            <IonLabel class="larger">
                                Profile
                            </IonLabel>
                        </IonCol>
                        {/*                         <IonCol pull="right">
                            <IonIcon icon={create} onClick={() => setEditMode(true)} color="primary" class="addlarge"></IonIcon>
                        </IonCol> */}
                    </IonRow>
                    <IonRow>
                        <IonCol offset="1">
                            <IonLabel>
                                {state.member.firstname} {state.member.lastname}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offset="1">
                            <IonLabel>
                                {state.member.phone}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offset="1">
                            <IonLabel>
                                {state.member.email}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol offset="1">
                            <IonLabel>
                                Subscription Level: {(state.member.subscriptionlevel === 88 ? "patron" : (state.member.subscriptionlevel === 0 ? "free" : "unlimited"))}
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow class="rowspacer">
                    </IonRow>
                    <IonRow>
                        <IonCol offset="1">
                            <IonButton onClick={signout}>Sign Out</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Settings;