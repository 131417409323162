import React, { useState } from "react";
import { IonButton, IonCol, IonGrid, IonLabel, IonRow, IonSelect, IonDatetime, IonSelectOption, IonInput, IonLoading, IonToast } from '@ionic/react';
import firebase from '../firebase';
import { routexl_api } from '../global/routexl'
import { systimeToHourMinute } from '../global/helperf'
import './TourRoute.css';

interface ContainerProps {
    setProperties: any;
    properties: any;
    path: string;
}

const TourRoute: React.FC<ContainerProps> = ({ setProperties, properties, path }) => {
    const [firstProperty, setFirstProperty] = useState<any>(properties[0]);
    const [lastProperty, setLastProperty] = useState(properties[properties.length - 1]);
    /* const [fromProperty, setFromProperty] = useState({ address: "From My Location", lat: 33.628814, lng: -117.949426 });
    const [toProperty, setToProperty] = useState({ address: "To My Location", lat: 33.628814, lng: -117.949426 }); */
    const [starttime, setStartTime] = useState("2020-01-01T09:00");
    const [route, setRoute] = useState<any>([]);
    const [viewtime, setViewTime] = useState("20");
    const [displaysave, setDisplaySave] = useState(false);
    const [confirmSave, setConfirmSave] = useState(false);
    const [fetchingRoute, displayFetchingRoute] = useState(false);

    const getRoutes = () => {
        setRoute([]);
        setDisplaySave(false);
        displayFetchingRoute(true);
        let routeProperties = [];
        routeProperties.push(firstProperty)
        properties.forEach((property: any) => {

            if (property.address !== firstProperty.address && property.address !== lastProperty.address)
                routeProperties.push(property)
        })
        routeProperties.push(lastProperty);

        routexl_api(routeProperties, viewtime)
            .then((result: any) => {
                const optimizedRoute = result.route;
                var keys = Object.keys(optimizedRoute);
                let tmpRoute: any[] = [];
                keys.forEach(function (key) {
                    tmpRoute.push(optimizedRoute[key])
                });
                setRoute(tmpRoute);
                displayFetchingRoute(false);
                setDisplaySave(true);
            })
            .catch(err => console.log(err))
    }

    const saveRoute = () => {
        let tmproute = [...route]
        if (tmproute.length > properties.length)
            tmproute.pop();
        tmproute.forEach((rprop: any) => {
            const index = properties.findIndex((aprop: any) => aprop.address === rprop.name);
            const ampmStr = arrival(rprop.arrival);

            let timestr = ampmStr.split(' ')[0];
            let hhmmArr = timestr.split(":");
            if (ampmStr.includes("am") && hhmmArr[0] === "12")
                hhmmArr[0] = "0";
            if (ampmStr.includes("pm") && hhmmArr[0] !== "12")
                hhmmArr[0] = (+hhmmArr[0] + 12).toString();
            const hhmm = parseInt(hhmmArr[0] + hhmmArr[1]);
            let textRef = firebase.database().ref(`${path}/${properties[index]["key"]}/`);
            textRef.update({ scheduledtime: hhmm })
                .then(() => {
                    let property = properties[index];
                    property.scheduledtime = hhmm;
                    //clone properties array; find property by key and splice (replace) in property with new time
                    const clonedproperties: any = [];
                    properties.forEach((val: any) => {
                        clonedproperties.push(Object.assign({}, val))
                    });
                    const idx = clonedproperties.findIndex((x: any) => x.key === property.key);
                    clonedproperties[idx].scheduledtime = property.scheduledtime;
                    clonedproperties.sort((a: any, b: any) => (a.scheduledtime > b.scheduledtime) ? 1 : -1);
                    setProperties(clonedproperties);
                    setConfirmSave(true)
                })
                .catch((err: any) => console.log("err", err))
        })
    }

    const arrival = (arrivaltime: any): string => {
        let newDateObj: Date;
        const hourminutes = systimeToHourMinute(starttime)
        let startTime: Date = new Date(2018, 1, 1, hourminutes[0], hourminutes[1], 0, 0);
        newDateObj = new Date(startTime.getTime() + arrivaltime * 60000);

        let coeff = 1000 * 60 * 5;
        let roundedDate = new Date(Math.round(newDateObj.getTime() / coeff) * coeff)

        let hours = roundedDate.getHours();
        let minutes = roundedDate.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        let strminutes = minutes.toString().padStart(2, '0');
        let strTime = `${hours}:${strminutes} ${ampm}`
        return strTime;
    }

    const distance = (property: any) => {
        const index = route.findIndex((aprop: any) => aprop.name === property.name);
        let retStr = "";
        if (property["distance"] === 0)
            retStr = ""
        else if (index !== 0) {
            const mileage = route[index]["distance"] - route[index - 1]["distance"]
            retStr = `${(mileage * 0.621371).toFixed(1)} miles`
        }
        else { // has to be a loop route
            const mileage = route[route.length - 1]["distance"] - route[route.length - 2]["distance"]
            retStr = `${(mileage * 0.621371).toFixed(1)} miles`
        }
        return retStr;
    }

    const setNewTime = (newtime: string) => {
        if (newtime.includes("T")) {
            setStartTime(newtime);
        }
    }

    return (
        <IonGrid>
            <IonRow class="vcenter smallRow">
                <IonCol size="3" sizeLg="2">
                    Begin At:
                </IonCol>
                <IonCol size="7" sizeLg="2">
                    <IonSelect value={firstProperty} onIonChange={e => { setFirstProperty(e.detail.value); setRoute([]); setDisplaySave(false) }} class="noleftpad">
                        {/*                         <IonSelectOption key={`from-mylocation `} value={fromProperty}>
                            My Location
                        </IonSelectOption> */}
                        {Array.isArray(properties) && properties.map((property: any) => {
                            return (
                                <IonSelectOption key={`from-${property.address}`} value={property}>
                                    {property.address}
                                </IonSelectOption>
                            )
                        })}
                    </IonSelect>
                </IonCol>
                <IonCol size="1" sizeLg="7"></IonCol>
            </IonRow>
            <IonRow class="vcenter smallRow">
                <IonCol size="3" sizeLg="2">
                    <IonLabel>End At:</IonLabel>
                </IonCol>
                <IonCol size="7" sizeLg="2">
                    <IonSelect value={lastProperty} onIonChange={e => { setLastProperty(e.detail.value); setRoute([]); setDisplaySave(false) }} class="noleftpad">
                        {/*                         <IonSelectOption key={`to-mylocation `} value={toProperty}>
                            My Location
                        </IonSelectOption> */}
                        {Array.isArray(properties) && properties.map((property: any) => {
                            return (
                                <IonSelectOption key={`to-${property.address}`} value={property}>
                                    {property.address}
                                </IonSelectOption>
                            )
                        })}
                    </IonSelect>
                </IonCol>
                <IonCol size="1" sizeLg="7"></IonCol>
            </IonRow >

            <IonRow class="vcenter smallRow">
                <IonCol size="3" sizeLg="2">
                    <IonLabel>Start Time:</IonLabel>
                </IonCol>
                <IonCol size="7" sizeLg="2">
                    <IonDatetime onIonChange={e => setNewTime(e.detail.value!)} defaultValue="2020-01-01T09:00Z" css-class="button-native" display-format="h:mm A" picker-format="h:mm A" value={starttime} minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"></IonDatetime>
                </IonCol>
                <IonCol size="1" sizeLg="7"></IonCol>
            </IonRow>

            <IonRow class="vcenter smallRow">
                <IonCol size="3" sizeLg="2">
                    <IonLabel>View Time:</IonLabel>
                </IonCol>
                <IonCol size="7" sizeLg="2">
                    <IonInput placeholder="20" maxlength={2} onIonChange={e => setViewTime(e.detail.value ? e.detail.value : "0")}></IonInput>
                </IonCol>
                <IonCol size="1" sizeLg="7"></IonCol>
            </IonRow>

            <IonRow class="halfrowspacer"></IonRow>

            <IonRow class="vcenter smallRow">
                <IonCol size="3" sizeLg="2"></IonCol>
                <IonCol size="7" sizeLg="2">
                    <IonButton color="primary" size="small" onClick={() => getRoutes()}>GET OPTIMIZED ROUTE</IonButton>
                </IonCol>
                <IonCol size="1" sizeLg="7"></IonCol>
            </IonRow>

            <IonRow>
                <IonCol>
                    <IonLoading isOpen={fetchingRoute} message="Just a sec, we're finding the optimal route for this tour."></IonLoading>
                </IonCol>
            </IonRow>

            <IonRow class="rowspacer"></IonRow>

            {
                route && route.map((property: any) => {
                    return (
                        <IonRow class="smallRow" key={`${property.name}-${property.arrival}`}>
                            <IonCol size="6">
                                {property.name}
                            </IonCol>

                            <IonCol size="3">
                                at {arrival(property.arrival)}
                            </IonCol>

                            <IonCol size="3">
                                {distance(property)}
                            </IonCol>
                        </IonRow>
                    )
                })
            }

            {
                displaysave && <IonRow class="vcenter smallRow">
                    <IonCol offset="3" size="8" sizeLg="3">
                        <IonButton color="primary" size="small" onClick={() => saveRoute()}>SAVE THIS ROUTE</IonButton>
                    </IonCol>
                </IonRow>
            }

            <IonToast
                isOpen={confirmSave}
                onDidDismiss={() => setConfirmSave(false)}
                message="Your Optimized Tour Route was saved."
                position="top"
                duration={1800}
                color="dark"
            />
        </IonGrid >
    )
}

export default TourRoute;