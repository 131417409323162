import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonListHeader, IonLabel, IonRow, IonCol, IonButton, IonToast, IonBackButton, IonButtons, IonGrid } from "@ionic/react";
import React, { useState, Fragment } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import AddProperties from "../components/AddProperties";

interface PropertyPageProps extends RouteComponentProps<{
    member: string;
    client: string;
    tourdate: string;
}> { }

const NTProperties: React.FC<PropertyPageProps> = ({ match }) => {
    const [properties, setProperties] = useState<any[]>([]);
    const history = useHistory();
    const [confirmToast, setConfirmToast] = useState(false);

    const hideAddNewProperty = () => {
    }

    const handleDone = () => {
        setConfirmToast(false);
        let routeProperties: any = [];
        properties.forEach((prop) => routeProperties.push(prop))
        history.push(
            {
                pathname: `/ntRoute/${match.params.member}/${match.params.client}/${match.params.tourdate}`,
                state: routeProperties
            })
        setProperties([]);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle class="capit"></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonListHeader>
                    <IonLabel class="labelTitle">
                        Let's Add Homes To Your Tour
                        </IonLabel>
                </IonListHeader>
                <IonGrid>
                    <Fragment>
                        <IonRow>
                            <IonCol>
                                <AddProperties setProperty={setProperties} toggleDisplay={hideAddNewProperty} setConfirmToast={setConfirmToast} memberid={match.params.member} clientid={match.params.client} tourdate={match.params.tourdate} currentproperties={properties} displaySetTime={false} displayCancel={false}></AddProperties>
                            </IonCol>
                        </IonRow>
                    </Fragment>
                    {(properties.length > 0) &&
                        <Fragment>
                            <IonRow class="rowspacer"></IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonLabel class="ion-padding-left ion-padding-top">Current Homes in Your Tour</IonLabel>
                                </IonCol>
                            </IonRow>
                        </Fragment>
                    }
                    {(properties.length > 0) &&
                        properties.map((property: any) => {
                            return (
                                <IonRow key={property.key} id={property.key}>
                                    <IonCol offset="1">
                                        <IonLabel class="labelSmall">{property.address}, {property.city}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            )
                        })
                    }
                    <IonRow class="rowspacer"></IonRow>
                </IonGrid>
                {(properties.length > 0) &&
                    <IonButton expand="full" onClick={() => handleDone()} >Done Adding Homes</IonButton>
                }
            </IonContent>
            <IonToast
                isOpen={confirmToast}
                onDidDismiss={() => setConfirmToast(false)}
                message="Great, The home was added to the tour. Add as many more homes as you like!"
                position="middle"
                duration={1400}
                color="dark"
                buttons={[
                    {
                        text: 'x',
                        role: 'cancel',
                        handler: () => {
                            ;
                        }
                    }
                ]}
            />
        </IonPage>
    );
};

export default NTProperties;