import {IonContent, IonHeader, IonImg, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToolbar} from '@ionic/react';
import React, {useContext, useEffect} from 'react';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/database';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {useHistory} from 'react-router-dom';
import {storeContext} from '../context/store';
import {useCookies} from "react-cookie";
import {randomkey, hideTabBar} from "../global/helperf";
import './signin.css'
import {gitCommitOutline} from 'ionicons/icons';

const Signin: React.FC = () => {
    const {dispatch} = useContext(storeContext)
    const [cookies, setCookie] = useCookies(['hs-path']);

    const history = useHistory();
    const uiConfig = {
        signInFlow: 'redirect',
        signInSuccessUrl: '/tours',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
                handleSignin(authResult);
                return false;
            }
        },
    };

    useEffect(() => {
        hideTabBar();
        const auth = firebase.auth();
        auth.onAuthStateChanged(user => {
            if (user) {
                if (!user.isAnonymous)
                    checkLoadMember(user.email, false)
                // ...
            }
        });
    }, []);

    const checkLoadMember = async (email: any, newmember: boolean) => {
        const memberExists = await firebase.database().ref(`/member`).orderByChild("email").equalTo(email).once('value').then(member => member.exists());
        if (memberExists) loadMember(email, newmember);
    }

    const gohome = () => {
        window.open('https://gohomestar.com', '_blank');
    }

    const loadMember = (email: any, newmember: boolean) => {
        let clients: any[] = [];
        let memberrec: any;
        let memberkey: any;
        let companyrec: any;

        let memberRef = firebase.database().ref(`/member`).orderByChild("email").equalTo(email);
        memberRef.once('value', (snapshot) => {
            if (!snapshot.val()) return;
            memberkey = Object.keys(snapshot.val())[0];
            memberrec = snapshot.val()[memberkey];
        }).then(() => {
            let companyRef = firebase.database().ref(`company/${memberrec.companyid}`);
            companyRef.once('value', (snapshot) => {
                companyrec = snapshot.val();
                if (companyrec.starcolor)
                    document.body.style.setProperty('--hs-star-color', companyrec.starcolor);
                if (companyrec.primarycolor) {
                    document.body.style.setProperty('--ion-color-primary', companyrec.primarycolor);
                    document.body.style.setProperty('--ion-color-primary-shade', companyrec.secondarycolor);
                    document.body.style.setProperty('--ion-color-primary-tint', companyrec.secondarycolor);
                }
            })
        }).then(() => {
            let clientsRef = firebase.database().ref(`/client/${memberkey}`).orderByChild("lastname");
            clientsRef.once('value', (snapshot) => {
                snapshot.forEach(record => {
                    let vals = record.val();
                    vals.id = record.key;
                    clients.push(vals);
                })
            }).then(() => {
                dispatch({
                    type: 'SET_TOURS', payload: {
                        member: {
                            id: memberkey,
                            firstname: memberrec.firstname,
                            lastname: memberrec.lastname,
                            email: email,
                            phone: memberrec.phone,
                            companyid: memberrec.companyid,
                            companyname: companyrec.name,
                            agent: memberrec.agent,
                            subscriptionlevel: memberrec.subscriptionlevel,
                            listingsource: memberrec.listingsource,
                            address: "",
                            city: "",
                            state: "",
                            postalcode: "",
                            logo: "",
                            primarycolor: companyrec.primarycolor,
                            secondarycolor: companyrec.secondarycolor,
                            starcolor: companyrec.starcolor,
                        },
                        clients: clients,
                        clientmode: false,
                        signedin: true

                    }
                });
                let expires = new Date();
                expires.setDate(expires.getDate() + 365);
                setCookie("hs-path", memberkey, {expires: expires, path: '/'});
                if (newmember)
                    history.push("/register");
                else
                    history.push("/tours");
            })
        })
    }

    const handleSignin = async (authres: any) => {
        const email = authres.user.email;
        if (authres.additionalUserInfo.isNewUser) {
            const fname = authres.user.displayName.substring(0, authres.user.displayName.lastIndexOf(" ") + 1).trim();
            const lname = authres.user.displayName.substring(authres.user.displayName.lastIndexOf(" ") + 1, authres.user.displayName.length).trim();
            const member = await firebase.database().ref(`/member`).orderByChild("email").equalTo(email).once('value').then(member => {
                return member;
            });
            if (!member.exists()) {
                const newmember = {agent: true, companyid: "co-homestar", email: email, firstname: fname, lastname: lname, officeid: "", autolist: true, phone: "", listingsource: "redfin", subscriptionlevel: 0};
                const memid = `mb-${randomkey(6)}`;
                firebase.database().ref(`/member/${memid}`).set(newmember).then(() => {
                    loadMember(email, true)
                })
            } else {
                firebase.database().ref(`/member/${Object.keys(member.val())[0]}`).update({
                    firstname: fname,
                    lastname: lname,
                }).then(() => {
                    loadMember(email, true)
                })
            }
        }
        else {
            loadMember(email, false)
        }
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle class="navTitle">
                        <IonImg src="/assets/hslogo.png" onClick={() => gohome()} class="hs-logo" alt="logo"></IonImg>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>

                <IonItem lines="none" class="ion-padding-top">
                    <IonLabel class="hsTitle">Welcome to HomeStar</IonLabel>
                </IonItem>

                <IonItem lines="none" class="item-text-wrap ion-padding-top">
                    <IonLabel class="hsIntro">
                        Enter your <strong> business email </strong> to sign in or sign up.
                    </IonLabel>
                </IonItem>

                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

                <IonItem lines="none" class="pad-top">
                    <IonText>
                        Interested in HomeStar? Awesome, take the next step and schedule a quick product demo to see how we can level-up your buyer-side business. <a href="https://calendly.com/jfreyder/product-demo" target="_blank" rel="noreferrer">Schedule Now!</a>
                    </IonText>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default Signin;