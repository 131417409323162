import React from "react";
import {IonCol, IonGrid, IonImg, IonRow, IonText} from '@ionic/react';

interface ContainerProps {
    img: any;
    title: any;
    message: any;
}

const BlankPage: React.FC<ContainerProps> = ({img, title, message}) => {

    return (
        <IonGrid>
            <IonRow class="rowspacer"></IonRow>
            <IonRow>
                <IonCol size="12" class="basicCenter">
                    {/* <IonIcon src={(icon === "info") ? informationCircleOutline : handLeftOutline} class="icon24"></IonIcon> */}
                    <IonImg src={img} class="blankimg"></IonImg>
                </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center">
                <h4 color="primary">{title}</h4>
            </IonRow>
            <IonRow>
                <IonCol offset="1" size="10">
                    <IonText>{message}</IonText>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default BlankPage;