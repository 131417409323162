import {ShepherdOptionsWithType} from 'react-shepherd'

const Steps: ShepherdOptionsWithType[] = [
  {
    id: "intro",
    attachTo: {
      element: ".newtour",
      on: "bottom"
    },
    scrollTo: false,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve(null);
        }, 500);
      });
    },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "DISMISS",
        type: "cancel"
      }
    ],
    highlightClass: "highlight",
    cancelIcon: {
      enabled: false
    },
    text: [
      "Add A Tour to Get Started!"
    ]
  }
];
export default Steps