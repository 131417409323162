import {IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonAlert, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRow, IonIcon, IonTitle, IonToolbar} from "@ionic/react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import firebase from 'firebase/compat/app';
import React, {useContext, useEffect, useState} from "react";
import {person, addCircle} from "ionicons/icons";
import {randomkey} from "../global/helperf";
import {useForm} from "react-hook-form";
import {storeContext} from '../context/store';
import './ntClients.css'

interface ClientPageProps extends RouteComponentProps<{
    member: string;
    tourdate: string;
}> {}
const NTClients: React.FC<ClientPageProps> = ({match}) => {
    const history = useHistory();
    const h: any[] = [];
    const {state, dispatch} = useContext(storeContext);
    const [clients, setClients] = useState(h);
    const {register, handleSubmit, reset, formState: {errors}} = useForm({defaultValues: {firstname: "", lastname: "", email: "", phone: "", budget: "", active: true}});
    const [displayForm, setDisplayForm] = useState(false);
    const [displayClientList, setDisplayClientList] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [title, setTitle] = useState("Select or Add a Client");

    useEffect(() => {
        setClients(state.clients);
        toggleForm(state.clients.length === 0);
    }, [state.clients, clients]);

    const styles = {
        input: {
            width: "80%",
            height: "30px",
        },
    };
    const toggleForm = (val: boolean) => {
        setDisplayForm(val);
        setDisplayClientList(!val);
        setTitle((val) ? "Add A Client" : "Select or Add a Client")
    }

    const onSubmit = (clientdata: any) => {
        const cid = `cl-${randomkey(6)}`;
        fetch('https://api-ssl.bitly.com/v4/shorten', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer a12cd39f10b4733eb829404f694a3c28781e95e3',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"long_url": `https://app.gohomestar.com/starnav/${match.params.member}${cid}`, "domain": "bit.ly", "group_guid": "Bl4u152kKWi"})
        }).then((response) => response.json())
            .then(bitdata => {
                const newclient = clientdata;
                newclient["shortlink"] = bitdata.id;
                newclient["active"] = true;
                firebase.database().ref(`/client/${match.params.member}/${cid}`).set(newclient).then(() => {
                    newclient["id"] = clientdata["id"] = cid;
                    dispatch({
                        type: 'ADD_CLIENT', payload: {
                            client: newclient,
                        }
                    });
                    setShowConfirm(true);
                    reset();
                    checkAndContinue(`/ntProperties/${match.params.member}/${cid}/${match.params.tourdate}`);
                });
                toggleForm(false);
            })
            .catch(err => console.log("err", err.message));
    };

    const handleSelectClient = (clientkey: string) => {
        checkAndContinue(`/ntProperties/${match.params.member}/${clientkey}/${match.params.tourdate}`);
    }

    const checkAndContinue = (rtPath: string) => {
        history.push(rtPath);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle class="capit"></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList>
                    <IonListHeader class="pb-20">
                        <IonLabel class="labelTitle">{title}</IonLabel>
                        <IonIcon icon={addCircle} color="primary" class="addlarge" onClick={() => toggleForm(true)}></IonIcon>
                    </IonListHeader>
                    {
                        displayClientList && clients.map(client => {
                            return (client && client.active && <IonItem key={client.id} lines="full" onClick={() => handleSelectClient(client.id)}><IonIcon class="person-icon" icon={person}></IonIcon>{client.firstname} {client.lastname}</IonItem>);
                        })
                    }

                </IonList>
                {displayForm &&
                    <form onSubmit={handleSubmit(onSubmit)} id="frmNewClient">
                        <IonGrid>
                            <IonRow class="formrow">
                                <IonCol>
                                    <input id="firstname" style={styles.input} {...register('firstname', {required: true, maxLength: 40})} placeholder='First Name' />
                                    {errors.firstname && errors.firstname.type === "required" && <span> required...</span>}
                                    {errors.firstname && errors.firstname.type === "maxLength" && <span>Max length exceeded</span>}
                                </IonCol>
                            </IonRow>
                            <IonRow class="formrow">
                                <IonCol>
                                    <input id="lastname" style={styles.input} {...register('lastname', {required: true, maxLength: 40})} placeholder='Last Name' />
                                    {errors.lastname && errors.lastname.type === "required" && <span> required...</span>}
                                    {errors.lastname && errors.lastname.type === "maxLength" && <span>Max length exceeded</span>}
                                </IonCol>
                            </IonRow>
                            <IonRow class="formrow">
                                <IonCol>
                                    <input id="budget" type='number' style={styles.input} {...register('budget', {required: true, maxLength: 11})} placeholder='Budget' />
                                    {errors.budget && errors.budget.type === "required" && <span> required...</span>}
                                    {errors.budget && errors.budget.type === "maxLength" && <span>Max length exceeded</span>}
                                </IonCol>
                            </IonRow>
                            <IonRow class="formrow">
                                <IonCol>
                                    <input id="email" style={styles.input} {...register('email', {required: true, maxLength: 50})} placeholder='Email' />
                                    {errors.email && errors.email.type === "maxLength" && <span>Max length exceeded</span>}
                                </IonCol>
                            </IonRow>
                            <IonRow class="formrow">
                                <IonCol>
                                    <input id="phone" style={styles.input} {...register('phone', {required: true, maxLength: 15})} placeholder='Phone' />
                                    {errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span>}
                                </IonCol>
                            </IonRow>
                            <IonRow class="pt-20">
                                <IonCol offset="2">
                                    <IonButton type="button" size="small" padding-end onClick={() => {reset(); toggleForm(false)}}>Cancel</IonButton>
                                    <IonButton type="submit" class="pl-30" size="small" padding-start>Add Client</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </form>
                }
            </IonContent>

            <IonAlert
                isOpen={showConfirm}
                onDidDismiss={() => setShowConfirm(false)}
                header="Congrats"
                message="Your client was added, now let's add some properties the the tour!"
                buttons={[
                    {
                        text: "Let's Go",
                        handler: () => {
                        }
                    }
                ]}
            ></IonAlert>

        </IonPage>

    );
}

export default NTClients;