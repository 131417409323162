import firebase from 'firebase/compat/app'

const firebaseConfig = {
    apiKey: "AIzaSyDAt4DfUqnu4oX9AslKCkDmdcUZNZWIMyU",
    authDomain: "homestars-dev.firebaseapp.com",
    databaseURL: "https://homestars-dev-default-rtdb.firebaseio.com",
    projectId: "homestars-dev",
    storageBucket: "homestars-dev.appspot.com",
    messagingSenderId: "686551524347",
    appId: "1:686551524347:web:222052ebd246e975e0215e",
    measurementId: "G-H89LHYQG1L"
};
firebase.initializeApp(firebaseConfig);
export default firebase;