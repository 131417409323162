import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext } from 'react';
import AgentContact from '../components/AgentContact';
import ClientContact from '../components/ClientContact';
import BlankPage from '../components/BlankPage';
import { storeContext } from '../context/store';

const Contact: React.FC = () => {
    const { state } = useContext(storeContext);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{(state.clientmode) ? "Contact" : "Contacts"}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >
                {state.clientmode && <AgentContact brokerdata={state.member}></AgentContact>}

                {!state.clientmode && <ClientContact clientdata={state.clients} memberid={state.member.id}></ClientContact>}

                {!state.clientmode && state.clients.length === 0 && <BlankPage img="/assets/sweet_home.svg" title="No Clients Yet" message="You will be able to add clients as you create tours.  Go to Tours to create your first Tour."></BlankPage>}
            </IonContent>
        </IonPage>
    );
};

export default Contact;