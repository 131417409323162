import { getPlatforms } from '@ionic/core'
import { Geolocation } from '@ionic-native/geolocation';

export const geoLocate = async () => {
  let lat: number;
  let lng: number;
  try {
    const position = await Geolocation.getCurrentPosition();
    lat = position.coords.latitude;
    lng = position.coords.longitude;
  } catch (e) {
    lat = 0.0;
    lng = 0.0;
  }
  return { lat, lng };
}

export function randomkey(length: number) {
  var result = [];
  var characters = 'abcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}

export function plural(count: number) {
  return (count > 1) ? "s" : "";
}

export function findClient(clients: any[], keys: string) {
  const clientkeys = keys.split('/');
  const client = clients.find(client => client.id === clientkeys[1])
  return client;
}

export function updateClient(clients: any[], client: any) {
  const clientkey = clients.findIndex(clientrec => clientrec.id === client.id)
  clients[clientkey] = client;
  return clients;
}

export function ioIOS(): boolean {
  const platforms = getPlatforms();
  return platforms.includes("ios");
}

export function systimeToNumber(systime: string): number {
  const tmparr = systime.split('T');
  const hoursminutes = tmparr[1].split(':');
  const hhmm = parseInt(hoursminutes[0] + hoursminutes[1]);
  return hhmm;
}

export function systimeToHourMinute(systime: string): number[] {
  const tmparr = systime.split('T');
  const hoursminutes = tmparr[1].split(':');
  const hhmm = [+hoursminutes[0], +hoursminutes[1]];
  return hhmm;
}

export function getFormattedTime(timeofday: number): string {
  if (timeofday === 0)
    return ("")
  else {
    const fourDigitTime = timeofday.toString().padStart(4, '0')
    var hours24 = parseInt(fourDigitTime.substring(0, 2));
    var hours = ((hours24 + 11) % 12) + 1;
    var amPm = hours24 > 11 ? 'pm' : 'am';
    var minutes = fourDigitTime.substring(2);
    return `${hours}:${minutes}  ${amPm}`;
  }
}

export function formatTourDate(date: any): string {
  const tourday = date.day.toString().padStart(2, '0');
  const tourmonth = date.month.toString().padStart(2, '0');
  const touryear = date.year.toString();
  return `${touryear}-${tourmonth}-${tourday}`;
}

export const tourdate = (tdate: string) => {
  const dateparts = tdate.split('-');
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return `${months[+dateparts[1] - 1]} ${dateparts[2]}`;
}

export const isoToMDY = (indate: string) => {
  const dateparts = indate.split('-');
  return `${dateparts[1]}/${dateparts[2]}/${dateparts[0]}`;
}

export function formatCurrency(amount: any = 0): string {
  amount = '' + amount;
  const amountNum = parseFloat(amount.replace(/\$|,/g, ''));
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return formatter.format(amountNum);
}

export function formatPhone(pnum: string): string {
  var match;
  let phonenum = "";
  const clean_num = ('' + pnum).replace(/\D/g, '');
  if (clean_num.length === 11) {
    match = clean_num.substr(1).match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match)
      phonenum = `+${clean_num.substr(0, 1)} (${match[1]}) ${match[2]}-${match[3]}`;
  }
  else if (clean_num.length === 7) {
    match = clean_num.match(/^(\d{3})(\d{4})$/);
    if (match)
      phonenum = `${match[1]}-${match[2]}`;
  }
  else {
    match = clean_num.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match)
      phonenum = `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  if (match) {
    return phonenum;
  }
  return "";
}

export const showTabBar = () => {
  const tabBar = document.getElementById('hsTabBar');
  if (tabBar !== null) {
    tabBar.style.display = 'flex';
  }
}

export const hideTabBar = () => {
  const tabBar = document.getElementById('hsTabBar');
  if (tabBar !== null) {
    tabBar.style.display = 'none';
  }
}

export const star_avg = (values: any) => {
  const filtered = values.filter((item: any) => item.starcount !== 0);
  const sum = filtered.reduce((sum: number, star: any) => (sum + +star.starcount), 0);
  const avg = sum / filtered.length;
  return avg
}

export const getDomainName = (urlstr: string): string => {
  let domain = ""
  if (urlstr.length !== 0) {
    const hostname = (new URL(urlstr)).hostname;
    const urlpieces = hostname.split(".")
    domain = (urlpieces.length === 2) ? urlpieces[0] : urlpieces[1] // either domain.com or www.domain.com
  }
  return domain;
}

export const sms = (phone: string, fname: string, date: string, link: string): string => {
  const seperator = "?&";
  const prefix = `sms:`;
  const smstext = `${prefix}${phone}${seperator}body=Hello%20${fname},%20I%20have%20scheduled%20a%20Home%20Tour%20for%20you%20on%20${date}.%20%20Please%20use%20this%20link%20for%20all%20the%20details.%20https://${link}`;
  return smstext
}