import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Profile from '../components/Profile';
import { storeContext } from '../context/store';

const Register: React.FC = () => {
    const { state } = useContext(storeContext);
    const [registered, setRegistered] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (registered) {
            setRegistered(false);
            history.push("/tours");
        }
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Register</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <h3>Welcome Aboard {state.member.firstname}!</h3>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            Please complete your registration so that we can get you up and running immediately.<br /><br />Each field is required.
                        </IonCol>
                    </IonRow>
                    <IonRow class="rowspacer"></IonRow>
                    <Profile setRegistered={setRegistered} member={state.member}></Profile>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Register;