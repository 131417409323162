import React, {useContext, useEffect, useState} from 'react';
import {IonContent, IonHeader, IonButton, IonCol, IonPage, IonTitle, IonToolbar, IonRow, IonIcon, IonAlert} from '@ionic/react';
import {starHalfOutline} from "ionicons/icons";
import firebase from 'firebase/compat/app';
import ToursByDate from '../components/ToursByDate';
import {storeContext} from '../context/store';
import {useHistory} from 'react-router-dom';
import {showTabBar} from '../global/helperf'
import {ShepherdOptionsWithType, ShepherdTour, TourMethods} from 'react-shepherd'
import Start from '../components/Start'
import productTour from '../components/ProductTour'
import './tours.css';
import './shepherd.css'

const Tours: React.FC = () => {
  const {state} = useContext(storeContext)
  const [futureTours, setFutureTours] = useState<any[]>([]);
  const [pastTours, setPastTours] = useState<any[]>([]);
  const [showPurchaseSubscription, setShowPurchaseSubscription] = useState(false);
  const [displayProductTour, setDisplayProductTour] = useState(false);

  const history = useHistory();
  let memid: any;
  let clid: any;

  useEffect(() => {
    if (state.member.id) {
      let tpath;
      if (state.clientmode) {
        tpath = firebase.database().ref(`/tour/${state.member.id}/${state.clients[0].id}`).orderByChild("date");
      }
      else
        tpath = firebase.database().ref(`/tour/${state.member.id}`).orderByChild("date");

      tpath.on("value", snapshot => {
        if (snapshot) {
          if (state.clientmode) {
            subscribeloadClientTours(snapshot);
          }
          else
            subscribeloadAgentTours(snapshot)
        }
      })
    }
    showTabBar();
  }, [])

  function subscribeloadAgentTours(snapshot: firebase.database.DataSnapshot) {
    memid = state.member.id;
    let records: any[] = [];
    snapshot.forEach(tours => {
      tours.forEach((tour) => {
        let proparray: any[] = [];
        let vals = tour.val();
        vals.key = `${memid}/${tours.key}`;
        vals.display = true;
        /*         const client = findClient(state.clients, `/${tours.key}`)
                vals.display = (client && client.active); */
        if (vals.properties) {
          for (let [key, value] of Object.entries(vals.properties)) {
            let pvalues: any = {};
            pvalues = value;
            pvalues.key = key;
            proparray.push(value);
          }
          proparray = proparray.sort((p1: any, p2: any) => {
            return p1.scheduledtime - p2.scheduledtime;
          });
          vals.properties = proparray;
          records.push(vals);
        }
      });
    })
    setPastFutureTours(records);
    /*     Issue when deleting only tour and then automatically returning to the Pour Page
        if (records.length === 0)
          setDisplayProductTour(true); */
  }

  function subscribeloadClientTours(snapshot: firebase.database.DataSnapshot) {
    memid = state.member.id;
    clid = state.clients[0].id;
    let records: any[] = [];
    snapshot.forEach(record => {
      let vals = record.val();
      vals.key = `${memid}/${clid}`;
      vals.display = true;
      if (vals.properties) {
        let proparray: any[] = [];
        for (let [key, value] of Object.entries(vals.properties)) {
          let pvalues: any = {};
          pvalues = value;
          pvalues.key = key;
          proparray.push(value);
        }
        proparray = proparray.sort((p1: any, p2: any) => {
          return p1.scheduledtime - p2.scheduledtime;
        });
        vals.properties = proparray;
        records.push(vals);
      }
    })
    setPastFutureTours(records);
  }

  const productTourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      }
    },
    useModalOverlay: true
  };

  const productSteps: ShepherdOptionsWithType[] = productTour;

  const setPastFutureTours = (records: any[]) => {
    let tmpdate = new Date();
    const offset = tmpdate.getTimezoneOffset();
    tmpdate = new Date(tmpdate.getTime() - (offset * 60 * 1000));
    const today = tmpdate.toISOString().split('T')[0];

    records = records.sort((p1: any, p2: any) => {
      return (p1.date > p2.date) ? 1 : -1;
    });
    let idx = -1;
    idx = records.findIndex(({date}) => date >= today);
    const pastTours = records.slice(0, idx);
    pastTours.reverse();
    const futureTours = records.slice(idx);
    setPastTours(pastTours);
    setFutureTours(futureTours);
  }

  const handleAddTour = () => {
    //        e.preventDefault();
    if (state.member.subscriptionlevel === 0 && (pastTours.length + futureTours.length > 0))
      setShowPurchaseSubscription(true);
    else
      history.push(`/ntCalendar/${state.member.id}`);
  }

  const handleTourReview = () => {
    history.push(
      {
        pathname: `/tourreview/1/1/1`,
        state: futureTours.concat(pastTours)
      })
  }

  const purchaseSubscription = () => {
    window.open('https://buy.stripe.com/dR68yOh0g6k2evCfYZ');
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Tours</IonTitle>
          {(!state.clientmode) && <IonButton slot="end" class="newtour" size="small" onClick={() => handleAddTour()} color="primary">Add A Tour</IonButton>}
          {/* {(!state.clientmode) && <IonIcon slot="end" icon={addCircle} onClick={() => handleAddTour()} color="primary" class="large">Get started</IonIcon>} */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRow class="pb-10 pt-10">
          <IonCol size="12" class=" ion-text-right">
            <IonIcon class="hsIcon" color="primary" icon={starHalfOutline} onClick={() => handleTourReview()}></IonIcon>
          </IonCol>
        </IonRow>
        <ToursByDate future={futureTours} past={pastTours}></ToursByDate>
      </IonContent>

      <IonAlert
        isOpen={showPurchaseSubscription}
        onDidDismiss={() => setShowPurchaseSubscription(false)}
        header="A free subscription provides you with one tour.  For unlimited tours, get your full subscription today. It's cheap!"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              ;
            }
          },
          {
            text: 'Get a Subscription',
            handler: () => {
              purchaseSubscription();
            }
          }
        ]}
      ></IonAlert>
      {((futureTours.length + futureTours.length === 0) && !state.clientmode) &&
        <ShepherdTour steps={productSteps} tourOptions={productTourOptions}>
          <TourMethods>
            {tourContext => <Start tContext={tourContext} displayProductTour={displayProductTour} />}
          </TourMethods>
        </ShepherdTour>
      }
    </IonPage>
  );
};

export default Tours;
