import React, { useState } from "react";
import { IonContent, IonButton, IonHeader, IonLabel, IonList, IonPage, IonRow, IonTitle, IonToolbar, IonSegment, IonSegmentButton, IonItem, IonCol, IonDatetime } from "@ionic/react";
import { useHistory, RouteComponentProps, useLocation } from "react-router-dom";
import firebase from '../firebase';
import TourRoute from '../components/TourRoute'
import "react-modern-calendar-datepicker/lib/DatePicker.css";

interface NTRoutePageProps extends RouteComponentProps<{
    member: string;
    client: string;
    tourdate: string;
}> { }

const NTRoute: React.FC<NTRoutePageProps> = ({ match }) => {
    const [activeTab, setActiveTab] = useState("manual");
    const history = useHistory();
    const location = useLocation<any>();
    const [properties, setProperties] = useState((location.state) ? location.state : []);

    const saveNewTime = (newtime: string, property: any) => {
        const tmparr = newtime.split('T');
        const hoursminutes = tmparr[1].split(':');
        const hhmm = parseInt(hoursminutes[0] + hoursminutes[1]);
        if (newtime.includes("T")) {
            let textRef = firebase.database().ref(`tour/${match.params.member}/${match.params.client}/${match.params.tourdate}/properties/${property.key}/`);
            textRef.update({ scheduledtime: hhmm }).then(() => {
                property.scheduledtime = hhmm;
                //clone properties array; find property by key and splice (replace) in property with new time
                const clonedproperties: any = [];
                properties.forEach((val: any) => {
                    clonedproperties.push(Object.assign({}, val))
                });
                const idx = clonedproperties.findIndex((x: any) => x.key === property.key);
                clonedproperties[idx].scheduledtime = property.scheduledtime;
                clonedproperties.sort((a: any, b: any) => (a.scheduledtime > b.scheduledtime) ? 1 : -1);
                setProperties(clonedproperties);

            }).catch((err: any) => console.log(err));
        }
    }

    const handleDone = () => {
        setActiveTab("manual")
        history.push(
            {
                pathname: `/invite/${match.params.client}`,
                state: { properties: properties, date: match.params.tourdate }
            })
        setProperties([]);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle class="capit">Route Your Tour</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonButton expand="full" size="default" onClick={() => handleDone()} >Done</IonButton>
                <IonRow class="rowspacer"></IonRow>
                <IonSegment value={activeTab}>
                    <IonSegmentButton value="manual" onClick={() => { setActiveTab("manual") }}>
                        <IonLabel>Manual</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="automatic" onClick={() => { setActiveTab("optimized") }}>
                        <IonLabel>Optimized</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {activeTab === "manual" ? (

                    <IonList>
                        <IonItem lines="none"></IonItem>
                        {Array.isArray(properties) && properties.map((property: any) => {
                            let ptime: number = property.scheduledtime;
                            let timestr = ptime.toLocaleString('en-US', {
                                minimumIntegerDigits: 4,
                                useGrouping: false
                            })
                            timestr = [timestr.slice(0, 2), ":", timestr.slice(2)].join('');
                            timestr = `2020-01-01T${timestr}`;
                            return (
                                <IonItem key={property.key} id={property.key} class="listpad">
                                    <IonCol size-lg="5" size="6">
                                        <IonLabel class="pb-10">
                                            {property.address}<br></br>
                                            {property.city}, {property.state}
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonDatetime onIonChange={e => saveNewTime(e.detail.value!, property)} defaultValue={`2021-01-01T19:00`} display-format="h:mm A" picker-format="h:mm A" value={timestr} minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"></IonDatetime>
                                    </IonCol>
                                </IonItem>
                            )
                        })
                        }
                    </IonList>
                ) :
                    (
                        <TourRoute setProperties={setProperties} properties={properties} path={`tour/${match.params.member}/${match.params.client}/${match.params.tourdate}/properties`}></TourRoute>
                    )
                }
            </IonContent>
        </IonPage>
    );
}

export default NTRoute;