import React, {useContext} from 'react';
import {RouteComponentProps, useLocation, useHistory} from 'react-router-dom';
import {IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonToast} from '@ionic/react';
import {chatboxOutline, copyOutline, mailOutline} from "ionicons/icons";
import {storeContext} from '../context/store';
import {findClient, getFormattedTime, sms, tourdate} from '../global/helperf'
import {db_client} from '../global/db_entity'
import './invite.css';

interface ContainerProps extends RouteComponentProps<{
    clientid: string,
}> {}

const Invite: React.FC<ContainerProps> = ({match}) => {
    const location = useLocation<any>();
    const {state} = useContext(storeContext);
    const history = useHistory();
    let client: any;

    client = findClient(state.clients, `${state.member.id}/${match.params.clientid}`);
    if (!client)
        client = db_client;
    const [present] = useIonToast();

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${client.shortlink}`)
        present({
            message: 'The tour link was copied to the clipboard.',
            duration: 1000,
            position: 'top',
            color: 'primary',
        })
    }

    const emailBody = () => {
        let emailbody = "";
        if (location.state && location.state.properties) {
            emailbody = `Hello ${client.firstname},%0D%0AHere is the schedule for the Home Tour and a link to review it online.  I look forward to seeing these homes with you.%0D%0A%0D%0A`
            emailbody += `${tourdate(location.state.date)}%0D%0A`;
            emailbody += location.state.properties.map((property: any) => `${getFormattedTime(property.scheduledtime)}%0D%0A${property.address}%0D%0A${property.city}, ${property.state} ${property.postalcode}%0D%0A%0D%0A`
            ).join('');
            emailbody += `View your Home Tour online:  https://${client.shortlink}%0D%0AUse this link to rate the homes on this tour, get directions, view the complete tour route, and keep up to date on any changes to the tour.`;
        }
        return emailbody;
    }

    const handleDone = () => {
        history.push("/tours");
    }

    const smsText = () => {
        const smstext = (location.state) ? sms(`${client.phone}`, `${client.firstname}`, `${location.state.date}`, `${client.shortlink}`) : "";
        return smstext;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Invite</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen >
                {/*         <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">More</IonTitle>
          </IonToolbar>
        </IonHeader> */}
                <IonGrid>
                    <IonRow>
                        <IonCol ion-padding-left>
                            <IonText>Great, the Home Tour for {client.firstname} is set up and it's time to send an invitation.  There are a few different ways to invite {client.firstname}.  </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow class="rowspacer"></IonRow>
                    <IonRow>
                        <IonCol size="2">
                            <IonButton fill="clear">
                                <a href={`mailto:${client.email ? client.email : ''}?subject=Your%20Home%20Tour&body=${emailBody()}`}>  <IonIcon size="large" icon={mailOutline} /></a>
                            </IonButton>
                        </IonCol>
                        <IonCol size="10">
                            <strong>Email.</strong> You can send the Home Tour itinerary via Email.  Just click the email icon to review it before you send it.
                        </IonCol>
                    </IonRow>
                    <IonRow class="rowspacer"></IonRow>
                    <IonRow>
                        <IonCol size="2">
                            <IonButton fill="clear">
                                <a href={smsText()}><IonIcon size="large" icon={chatboxOutline} /></a>
                            </IonButton>
                        </IonCol>
                        <IonCol size="10">
                            <strong>Text.</strong> You can send the Home Tour itinerary via Text.  Just click the text icon to review it before you send it.
                        </IonCol>
                    </IonRow>
                    <IonRow class="rowspacer"></IonRow>
                    <IonRow>
                        <IonCol size="2">
                            <IonButton fill="clear" onClick={() => handleCopyLink()}>
                                <IonIcon icon={copyOutline}></IonIcon>
                            </IonButton>
                        </IonCol>
                        <IonCol size="10">
                            <strong>Link.</strong> You can copy a link to the Home Tour itinerary to the clipboard.  Then you can paste the link anywhere you like.
                        </IonCol>
                    </IonRow>
                    <IonRow class="rowspacer"></IonRow>
                </IonGrid>

                <IonRow class="ion-padding-top ion-padding-bottom">
                    <IonCol class="ion-text-center"><IonButton onClick={() => handleDone()}>Done</IonButton></IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default Invite;