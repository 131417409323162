import {IonLabel, IonRow, IonCol, IonButton, IonDatetime, IonToast, IonGrid, IonTextarea, IonToggle, IonItem} from "@ionic/react";
import firebase from 'firebase/compat/app';
import React, {useRef, useState, useContext} from "react";
import {storeContext} from '../context/store';
import Geosuggest from 'react-geosuggest';
import {getFormattedTime, systimeToNumber} from '../global/helperf';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import './AddProperties.css'
const parseGooglePlace = require('parse-google-place');

interface ContainerProps {
    setProperty: any;
    toggleDisplay: any;
    setConfirmToast: any;
    memberid: string;
    clientid: string;
    tourdate: string;
    currentproperties: any;
    displaySetTime: boolean;
    displayCancel: boolean;
}

const AddProperties: React.FC<ContainerProps> = ({setProperty, toggleDisplay, setConfirmToast, memberid, clientid, tourdate, currentproperties, displaySetTime, displayCancel}) => {
    const {state} = useContext(storeContext);
    const [time, setTime] = useState("2020-01-01T00:00");
    const [singleProperty, setSingleProperty] = useState(true);
    const [multiProperties, setMultiProperties] = useState<string>("");
    const gplaces = useRef(null)
    const [addBtnDisabled, setAddBtnDisabled] = useState(true);
    const [duptimeToast, setDuptimeToast] = useState(false);
    // const [selectedDay, setSelectedDay] = useState<DayValue>(null);

    const defaultProperty = {
        address: "",
        city: "",
        countryLong: "",
        countryShort: "",
        county: "",
        stateLong: "",
        stateShort: "",
        streetName: "",
        streetNumber: "",
        offerduedate: "",
        listingUrl: "",
        zipCode: "",
        lat: "",
        lng: ""
    }
    const [propertylocation, setPropertyLocation] = useState(defaultProperty);

    const handleAddProperty = () => {
        if (singleProperty)
            addSingleProperty()
        else
            addMultipleProperties()
    }

    const addMultipleProperties = () => {
        let propertyList = [];
        const lines = multiProperties.split("\n");

        /*         var config = {
                    method: 'get',
                    url: 'https://maps.googleapis.com/maps/api/place/details/json?fields=name%2Cgeometry%2Cformatted_address%2Caddress_components&place_id=ChIJZ-uTQ0Ag3YAR73PRCZosbjs&key=AIzaSyB6TUbheAzf6thBLlEQMfEMoGy6ZZm2-ts',
                    headers: {}
                };
         */

        var service = new google.maps.places.PlacesService(document.createElement('div'));
        var detailservice = new google.maps.places.PlacesService(document.createElement('div'));
        for (var i = 0; i < lines.length; i++) {
            let request = {
                query: lines[i].trim(),
                fields: ['place_id']
            };
            service.findPlaceFromQuery(request, function (results, status) {
                if (results) {
                    const detailRequest = {
                        placeId: (results[0].place_id) ? results[0].place_id : "",
                        fields: ['address_components', 'geometry'],
                    }
                    if (results[0].place_id)
                        detailservice.getDetails(detailRequest, function (dresults: any, dstatus: any) {
                            if (dresults) {
                                let newPlace = parseGooglePlace(dresults);
                                newPlace.lat = dresults.geometry.location.lat;
                                newPlace.lng = dresults.geometry.location.lng;
                                newPlace.listingUrl = "";
                                setPropertyLocation(newPlace);
                                addSingleProperty()
                            }
                        })
                }
                else
                    console.log("status", status)
            })
            propertyList.push(lines[i].trim());
        }
    }

    const addSingleProperty = () => {
        let hhmm = systimeToNumber(time);
        if (hhmm !== 0 && currentproperties.some((prop: any) => prop.scheduledtime === hhmm))
            setDuptimeToast(true);
        else {
            if (!displaySetTime)
                hhmm = 12;
            const ptemplate = {
                key: "", address: "", baths: 0, beds: 0, estimatedValue: 600000, city: "", listingurl: "", lat: "", lng: "", postalcode: "", primaryphoto: "", offerduedate: "",
                outside: [null, {starcount: 0, starname: "Garage"}, {starcount: 0, starname: "Patio"}, {starcount: 0, starname: "Front Yard"}, {starcount: 0, starname: "Backyard"}, {starcount: 0, starname: "Landscaping"}, {starcount: 0, starname: "Pool"}],
                overall: [null, {starcount: 0, starname: "Layout"}, {starcount: 0, starname: "Kitchen"}, {starcount: 0, starname: "Bedrooms"}, {starcount: 0, starname: "Bathrooms"}, {starcount: 0, starname: "Quality of Renovation"}, {starcount: 0, starname: "Neighborhood"}, {starcount: 0, starname: "Lot Size"}, {starcount: 0, starname: "Backyard"}, {starcount: 0, starname: "Front Yard"}],
                rooms: [null, {starcount: 0, starname: "Kitchen"}, {starcount: 0, starname: "Dining Room"}, {starcount: 0, starname: "Living Room"}, {starcount: 0, starname: "Master Bedroom"}, {starcount: 0, starname: "2nd Bedroom"}, {starcount: 0, starname: "Master Bathroom"}, {starcount: 0, starname: "Second Bathroom"}], scheduledtime: 0, stars: 0, state: ""
            };
            firebase.database().ref(`/tour/${memberid}/${clientid}/${tourdate}`).update({date: tourdate}).then(() => {
                let newProperty = ptemplate;
                newProperty.address = propertylocation.address;
                newProperty.city = propertylocation.city;
                newProperty.state = propertylocation.stateShort;
                newProperty.postalcode = propertylocation.zipCode;
                newProperty.lat = propertylocation.lat;
                newProperty.lng = propertylocation.lng;
                newProperty.listingurl = propertylocation.listingUrl;
                fetch(`https://maps.googleapis.com/maps/api/streetview/metadata?size=600x400&location=${propertylocation.address},${propertylocation.city},${propertylocation.stateShort},${propertylocation.zipCode}&key=AIzaSyB6TUbheAzf6thBLlEQMfEMoGy6ZZm2-ts`)
                    .then((response) => response.json())
                    .then(data => {
                        if (data.status === "OK")
                            newProperty.primaryphoto = `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${propertylocation.address},${propertylocation.city},${propertylocation.stateShort},${propertylocation.zipCode}&key=AIzaSyB6TUbheAzf6thBLlEQMfEMoGy6ZZm2-ts`;
                        else
                            newProperty.primaryphoto = "/assets/no_photo.png";
                        newProperty.scheduledtime = hhmm;
                        newProperty.offerduedate = ''; //(selectedDay) ? `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}` : ''

                        var newPropRef = firebase.database().ref(`/tour/${memberid}/${clientid}/${tourdate}/properties/`).push()
                        const newPropertyKey: any = newPropRef.key;
                        newPropRef.set(newProperty).then(() => {
                            newProperty.key = newPropertyKey;
                            const cproperties = currentproperties;
                            cproperties.push(newProperty);
                            cproperties.sort((a: any, b: any) => (a.scheduledtime > b.scheduledtime) ? 1 : -1);
                            setListingURL(newProperty)
                            setProperty(cproperties);
                            setPropertyLocation(defaultProperty);
                            setConfirmToast(true);
                            setAddBtnDisabled(true);
                            toggleDisplay(false);
                            });
                        });
            })
        }
    }

    const setTheAddress = (place: any) => {
        if (gplaces !== null) {
            if (gplaces.current !== null) {
                const tmpelement = gplaces.current as any
                tmpelement.blur();
                tmpelement.clear();
            }
        }
        if (place) {
            let newPlace = parseGooglePlace(place.gmaps);
            newPlace.lat = place.location.lat;
            newPlace.lng = place.location.lng;
            newPlace.listingUrl = "";
            setPropertyLocation(newPlace);
            setAddBtnDisabled(false);
        }
    }

    function setListingURL(property: any) {
        fetch(`https://api.valueserp.com/search?api_key=1BF262B7218E4307A4BD1508E3CD19BC&q=${state.member.listingsource} ${property.address} ${property.city} ${property.state} ${property.postalcode}&include_html=false&num=2&include_fields=organic_results.link`)
            .then((response) => response.json())
            .then((data: any) => {
                if (data) {
                    const organic = data.organic_results;
                    const listingurl = organic[0].link;
                    var propRef = firebase.database().ref(`/tour/${memberid}/${clientid}/${tourdate}/properties/${property.key}/`);
                    propRef.update({listingurl})
                        .then((res => {}))
                }
            })
    }

    const setNewTime = (newtime: string) => {
        if (newtime.includes("T")) {
            setTime(newtime);
        }
    }

    const toggleSingleProperty = () => {
        if (singleProperty) setAddBtnDisabled(false)
        setSingleProperty(!singleProperty)
    }

    const datepickerRef = useRef<HTMLIonDatetimeElement>(null);

    return (
        <IonGrid>
            {!displaySetTime && false &&
                <IonRow class="ion-padding-top">
                    <IonCol size="10">
                        <IonItem>
                            <IonLabel>Add Multiple Properties</IonLabel>
                            <IonToggle color="primary" name="multipleProp" slot="start" onClick={() => toggleSingleProperty()} class="hsToggle">

                            </IonToggle>
                        </IonItem>
                    </IonCol>
                </IonRow>
            }

            <IonRow class="ion-padding-top">
                {displaySetTime &&
                    <IonCol size="2" size-lg="1">
                        <IonLabel class="numberCircle">1</IonLabel>
                    </IonCol>
                }
                {singleProperty &&
                    <IonCol size="10" size-lg="11">
                        <Geosuggest
                            ref={gplaces}
                            placeholder="Enter an address"
                            onSuggestSelect={setTheAddress}
                            country="us"
                            minLength={4}
                            autoComplete="off"
                        />
                    </IonCol>
                }
                {!singleProperty &&
                    <IonCol size="10" size-lg="11">
                        <IonTextarea onIonChange={e => setMultiProperties(e.detail.value!)} placeholder="Enter one property per line" rows={10} cols={40}>

                        </IonTextarea>
                    </IonCol>
                }
            </IonRow>

            { (propertylocation.address.length > 0) &&
                <IonRow class="center-address-row">
                    {displaySetTime &&
                        <IonCol size="2" size-lg="1">
                        </IonCol>
                    }
                    <IonCol>
                        {propertylocation.address}<br />{propertylocation.city}, {propertylocation.stateShort} {propertylocation.zipCode}
                    </IonCol>
                </IonRow>
            }

            { (propertylocation.address.length > 0) &&
                <IonRow class="center-address-row">
                    {displaySetTime &&
                        <IonCol size="2" size-lg="1">
                        </IonCol>
                    }
                </IonRow>
            }

            {displaySetTime &&
                <IonRow class="center-row">
                    <IonCol size="2" size-lg="1">
                        <IonLabel class="numberCircle">2</IonLabel>
                    </IonCol>
                    <IonCol size="10" size-lg="11">
                        {(time === "2020-01-01T00:00") &&
                            <IonButton size="small" fill="outline" onClick={() => datepickerRef.current?.click()}>Set The Time</IonButton>
                        }
                        <IonDatetime className={(time === "2020-01-01T00:00") ? "ion-hide" : "ion-show"} ref={datepickerRef} onIonChange={e => setNewTime(e.detail.value!)} defaultValue="2020-01-01T09:00Z" css-class="button-native" display-format="h:mm A" picker-format="h:mm A" value={time} minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"></IonDatetime>

                    </IonCol>
                </IonRow>
            }
            <IonRow class="rowspacer"></IonRow>
            <IonRow>
                {displayCancel &&
                    <IonCol size="4" offset="2" size-lg="2">
                        <IonButton size="small" onClick={() => toggleDisplay(false)}>Cancel</IonButton>
                    </IonCol>
                }
                <IonCol size="6" size-lg="3">
                    <IonButton size="small" disabled={addBtnDisabled} onClick={() => handleAddProperty()}>Add Home to Tour</IonButton>
                </IonCol >
            </IonRow>

            <IonToast
                isOpen={duptimeToast}
                onDidDismiss={() => setDuptimeToast(false)}
                message={`You already have a home scheduled at ${getFormattedTime(systimeToNumber(time))}.  Please set a different time for this home.`}
                position="top"
                duration={6400}
                color="dark"
                buttons={[
                    {
                        text: 'x',
                        role: 'cancel',
                        handler: () => {
                            ;
                        }
                    }
                ]}
            />
        </IonGrid>
    )
};
export default AddProperties;