export function routexl_api(properties: any, servicetime: any) {
    let locations: any = [];
    properties.forEach((property: any) => {
        if ( property.address)
        locations.push({ 'name': property.address, 'lat': property.lat, 'lng': property.lng, 'servicetime': (property.address.includes("My Location")) ? 0 : servicetime })
    });
    var httpRequest = new XMLHttpRequest();
    // Set up the request and send it
    return new Promise((resolve, reject) => {
        httpRequest.open('POST', "https://api.routexl.com/tour");
        httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        httpRequest.setRequestHeader("Authorization", "Basic " + btoa("oatcg:dSjRRJp7XMz_5c9="));
        var params = "locations=" + JSON.stringify(locations);
        httpRequest.send(params);

        // Receive the response
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    resolve(JSON.parse(httpRequest.responseText));
                } else {
                    console.log('request_error');
                }
            }
        };
    });
}
