
import React, {Fragment, useContext} from "react";
import {IonItem, IonRow} from '@ionic/react';
import {storeContext} from '../context/store';
import TimeLineBody from '../components/TimelineBody';
import BlankPage from '../components/BlankPage';

interface ContainerProps {
    future: any[];
    past: any[];
}

const ToursByDate: React.FC<ContainerProps> = ({future, past}) => {
    const {state} = useContext(storeContext)
    const futureT =
        <Fragment>
            {
                future.map(ftour => {
                    return (ftour.properties && ftour.display && <TimeLineBody key={`${ftour.key}-${ftour.date}`} tourkey={ftour.key} startdate={ftour.date} properties={ftour.properties}></TimeLineBody>);
                })
            }
        </Fragment>
    const pastT = <Fragment>
        {
            past.map(ptour => {
                return (ptour.properties && ptour.display && <TimeLineBody key={`${ptour.key}-${ptour.date}`} tourkey={ptour.key} startdate={ptour.date} properties={ptour.properties}></TimeLineBody>);
            })
        }
    </Fragment>
    return (
        <Fragment>
            {futureT}
            {
                past.length > 0 &&
                <Fragment>
                    <IonRow class="rowspacer"></IonRow>
                    <IonItem class="labelTitle">Past Tours</IonItem>
                </Fragment>
            }
            {pastT}
            {((future.length + past.length === 0) && state.clientmode) && <IonItem>Sorry, this tour has been deleted.  Please click on the Contact button to get more information from your agent.</IonItem>}
            {((future.length + past.length === 0) && !state.clientmode) && <BlankPage img="/assets/adventure_map.svg" title="Let's Get Started" message="Click on the Add A Tour button above, to add your first tour."></BlankPage>}
        </Fragment>
    )
}

export default ToursByDate;