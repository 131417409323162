import React, {useState, useContext} from "react";
import firebase from 'firebase/compat/app';
import {useForm} from "react-hook-form";
import {storeContext} from '../context/store';
import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonModal, IonRow, IonAlert, IonToast} from '@ionic/react';
import {phonePortraitOutline, mailOutline, chatboxOutline} from 'ionicons/icons';
import './ClientContact.css';
import {findClient, formatCurrency, formatPhone, updateClient} from "../global/helperf";

interface ContainerProps {
    clientdata: any;
    memberid: string;
}

const ClientContact: React.FC<ContainerProps> = ({clientdata, memberid}) => {
    const {dispatch} = useContext(storeContext);
    const [showModal, setShowModal] = useState(false);
    const h: any = {};
    const [client, setClient] = useState(h);
    const [confirmToast, setConfirmToast] = useState(false);
    const {register, handleSubmit, reset, formState: {errors}} = useForm({defaultValues: {firstname: "", lastname: "", email: "", phone: "", budget: "", active: true}});
    const [displayForm, setDisplayForm] = useState(false);
    const [displayDeleteConfirm, setDisplayDeleteConfirm] = useState(false);

    const showClientModal = (clientid: any) => {
        const currclient = findClient(clientdata, `null/${clientid}`);
        setClient(currclient);
        setShowModal(true);
    }

    const confirmDelete = (clientid: any) => {
        const currclient = findClient(clientdata, `null/${clientid}`);
        setClient(currclient);
        setDisplayDeleteConfirm(true);
    }

    const deleteContact = () => {
        let delRef = firebase.database().ref(`tour/${memberid}/${client.id}/`);
        delRef.remove().then(() => {
            let delRef = firebase.database().ref(`client/${memberid}/${client.id}/`);
            delRef.remove().then(() => {
                dispatch({
                    type: 'DELETE_CLIENT', payload: {
                        client: client,
                    }
                });
                const idx = clientdata.indexOf(client);
                if (idx > -1)
                    clientdata.splice(idx, 1)
                setDisplayForm(false);
                setShowModal(false);
            });
        }).catch(err => console.log(err))
    }

    const styles = {
        input: {
            width: "100%",
            height: "30px",
        },
        chkbox: {
            width: "30px",
        },
    };

    const onSubmit = (clientupdate: any) => {
        clientdata = updateClient(clientdata, clientupdate);
        let updaterecord = {...clientupdate};

        delete updaterecord['id'];
        firebase.database().ref(`/client/${memberid}/${clientupdate['id']}`).update(updaterecord).then(() => {
        });
        setConfirmToast(true);
        setDisplayForm(false);
        setShowModal(false);
    };

    const showForm = (clientid: string) => {
        const editclient = findClient(clientdata, `null/${clientid}`);
        reset(editclient);
        setDisplayForm(true);
        return true;
    }

    return (
        <IonGrid>
            {clientdata.map((client: any) => {
                return (client && <IonItem key={client.id} onClick={() => showClientModal(client.id)} lines="full">{client.firstname} {client.lastname} {(!client.active) ? "(Inactive)" : ""} </IonItem>);
            })}

            <IonModal isOpen={showModal} onDidDismiss={() => {setShowModal(false)}} >

                <IonButton onClick={() => {setDisplayForm(false); setShowModal(false)}}>
                    CLOSE
                </IonButton>
                {!displayForm &&
                    <IonGrid class="frmGrid">
                        <IonRow class="client-content">
                            <IonLabel>{client.firstname} {client.lastname}</IonLabel>
                        </IonRow>
                        <IonRow class="client-content">
                            <IonCol class="col-right" size="3">
                                <a href={`tel:${client.phone}`}>
                                    <IonIcon class="col-right agent-icons" float-right icon={phonePortraitOutline} />
                                </a>
                            </IonCol>
                            <IonCol class="col-center agent-icons" size="3">
                                <a href={`mailto:${client.email}`}>  <IonIcon icon={mailOutline} /></a>
                            </IonCol>
                            <IonCol class="agent-icons" size="3">
                                <a href={`sms://${client.phone}`}><IonIcon icon={chatboxOutline} /></a>
                            </IonCol>
                        </IonRow>
                        <IonRow class="client-content">
                            <IonLabel>{formatPhone(client.phone)}</IonLabel>
                        </IonRow>
                        <IonRow class="client-content">
                            <IonLabel>{client.email}</IonLabel>
                        </IonRow>
                        <IonRow class="client-content">
                            <IonLabel>Budget: {formatCurrency(client.budget)}</IonLabel>
                        </IonRow>
                        <IonRow class="client-content">
                            <IonCol size="6">
                                <IonButton size="small" onClick={() => confirmDelete(client.id)}>Delete Contact</IonButton>
                            </IonCol>
                            <IonCol size="6" class="alignRight">
                                <IonButton size="small" onClick={() => showForm(client.id)}>Edit Contact</IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>}
                {displayForm &&
                    <IonGrid class="frmGrid">
                        <form onSubmit={handleSubmit(onSubmit)} id="frmUpdateClient">
                            <IonGrid>
                                <IonRow class="halfrowspacer"></IonRow>
                                <IonRow class="formrow">
                                    <IonCol>
                                        <input id="firstname" style={styles.input} {...register('firstname', {required: true, maxLength: 40})} placeholder='First Name' />
                                        {errors.firstname && errors.firstname.type === "required" && <span> required...</span>}
                                        {errors.firstname && errors.firstname.type === "maxLength" && <span>Max length exceeded</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow class="formrow">
                                    <IonCol>
                                        <input id="lastname" style={styles.input} {...register('lastname', {required: true, maxLength: 40})} placeholder='Last Name' />
                                        {errors.lastname && errors.lastname.type === "required" && <span> required...</span>}
                                        {errors.lastname && errors.lastname.type === "maxLength" && <span>Max length exceeded</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow class="formrow">
                                    <IonCol>
                                        <input id="budget" type='number' style={styles.input} {...register('budget', {required: true, maxLength: 11})} placeholder='Budget' />
                                        {errors.budget && errors.budget.type === "required" && <span> required...</span>}
                                        {errors.budget && errors.budget.type === "maxLength" && <span>Max length exceeded</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow class="formrow">
                                    <IonCol>
                                        <input id="email" style={styles.input} {...register('email', {maxLength: 50})} placeholder='Email' type="email" />
                                        {errors.email && errors.email.type === "maxLength" && <span>Max length exceeded</span>}
                                    </IonCol>
                                </IonRow>
                                <IonRow class="formrow">
                                    <IonCol>
                                        <input id="phone" style={styles.input} {...register('phone', {maxLength: 15})} placeholder='Phone' />
                                        {errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span>}
                                    </IonCol>
                                </IonRow>

                                <IonRow class="formrow">
                                    {/* <IonCol size="3">
                                <IonLabel class="labelpad">Phone</IonLabel>
                            </IonCol> */}
                                    <IonCol>
                                        Active: <input id="active" type="checkbox" color="primary" {...register('active')} />
                                    </IonCol>
                                </IonRow>

                                <IonRow class="pt-20">
                                    <IonCol size="5" offset="1">
                                        <IonButton type="button" size="small" padding-start onClick={() => {reset(); setDisplayForm(false)}}>Cancel</IonButton>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonButton type="submit" size="small" padding-end>Update Client</IonButton>
                                    </IonCol>
                                </IonRow>

                            </IonGrid>
                        </form>
                    </IonGrid>
                }
            </IonModal>

            <IonToast
                isOpen={confirmToast}
                onDidDismiss={() => setConfirmToast(false)}
                message="Your client information was updated."
                position="top"
                duration={1600}
                color="dark"
            />

            <IonAlert
                isOpen={displayDeleteConfirm}
                onDidDismiss={() => setDisplayDeleteConfirm(false)}
                header="This Contact and all the Tours for this Contact will be deleted."
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            ;
                        }
                    },
                    {
                        text: 'Yes, Delete It',
                        handler: () => {
                            deleteContact();
                        }
                    }
                ]}
            ></IonAlert>


        </IonGrid>
    )
}

export default ClientContact;