import React, {Fragment, useContext, useEffect, useState} from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonTextarea, IonGrid, IonRow, IonRange} from '@ionic/react';
import firebase from '../firebase';
import StarRating from '../components/StarRating';
import BlankPage from '../components/BlankPage';
import {storeContext} from '../context/store';
import {findClient, star_avg} from '../global/helperf'
import './overall.css';

const Overall: React.FC = () => {
  const {state} = useContext(storeContext);
  const [overallStars, setOverallStars] = useState([{starid: "", starname: "", starcount: 0}]);
  const [propertystars, setPropertyStars] = useState(-1);
  const [text, setText] = useState("");
  const [propertyid, setPropertyId] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [minValuation, setMinValuation] = useState(100000);
  const [maxValuation, setMaxValuation] = useState(10000000);
  const [valuation, setValuation] = useState(600000);
  const [pavailable, setPavailable] = useState(false);

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  if (state.property) {
    if (!propertyid || (propertyid && state.property.key !== propertyid)) {
      setPavailable(true);
      setPropertyId(state.property.key);
      setPropertyStars(state.property.stars);
      setText(state.property.overalltext);
      setPropertyAddress(`${state.property.address}, ${state.property.city}`)
      setValuation(state.property.estimatedValue);
      const tmptourid = (state.tourid ? state.tourid : "");
      const client = findClient(state.clients, tmptourid);
      let budgetstr = client.budget + '';

      const budget = parseFloat(budgetstr.replace(/\$|,/g, ''));
      setMinValuation((budget > 0) ? budget - 100000 : 200000);
      setMaxValuation((budget > 0) ? budget + 200000 : 10000000);

      const overalldata = state.property.overall;
      const starkeys = Object.keys(overalldata);
      const mystars = starkeys.map(key => {
        return {starid: key, starname: overalldata[key].starname, starcount: overalldata[key].starcount}
      });
      setOverallStars(mystars);
    }
  }

  useEffect(() => {
    let spath = firebase.database().ref(`${state.path}`);
    spath.on("value", snapshot => {
      if (snapshot && snapshot.val() && state.property) {
        if (snapshot && state.property.stars !== snapshot.val().stars) {
          setPropertyRating("", snapshot.val().stars);
        }
        if (state.property.estimatedValue !== snapshot.val().estimatedValue)
          setValuation(snapshot.val().estimatedValue)
        if (state.property.overalltext !== snapshot.val().overalltext)
          setText(snapshot.val().overalltext)
      }
    })

    let opath = firebase.database().ref(`${state.path}/overall`);
    opath.on("value", snapshot => {
      const newstars: {
        starid: string;
        starname: any;
        starcount: any;
      }[] = [];
      snapshot.forEach(record => {
        if (record.key) {
          newstars.push({starid: record.key, starname: record.val().starname, starcount: record.val().starcount});
        }
      });

      if (JSON.stringify(overallStars) !== JSON.stringify(newstars)) {
        setOverallStars(newstars);
      }
    })
  });

  const setStarRating = (starid: string, starcount: number) => {
    const idx = overallStars.map(function (x) {return x.starid;}).indexOf(starid);
    let starRef = firebase.database().ref(`${state.path}/overall/${starid}`);
    starRef.update({starname: overallStars[idx].starname, starcount: starcount}).then(() => null);
    let newstars = overallStars;
    newstars[idx] = {starid: starid, starname: overallStars[idx].starname, starcount: starcount};
    state.property.overall = newstars;
    const avg = star_avg(newstars);
    setPropertyRating("", avg)
  }

  const setPropertyRating = (ignore: string, starcount: number) => {
    let starRef = firebase.database().ref(`${state.path}`);
    starRef.update({stars: starcount})
      .catch(err => console.log(err));
    state.property.stars = starcount;
    setPropertyStars(starcount);
  }

  const textblur = (e: any) => {
    const textstr = (e.target as HTMLInputElement).value;
    let textRef = firebase.database().ref(`${state.path}`);
    textRef.update({overalltext: textstr}).then(() => null).catch(err => console.log(err));
    state.property.overalltext = textstr;
    setText(textstr);
  }

  const setRangeValue = (val: any) => {
    setValuation(val);
  };

  const valuationBlur = () => {
    let valRef = firebase.database().ref(`${state.path}`);
    valRef.update({estimatedValue: valuation}).then(() => null).catch(err => console.log(err));
    state.property.estimatedValue = valuation;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Rate the Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {!pavailable && <BlankPage img="/assets/rate.svg" title="There's No Tour Selected" message="You need to select a home from a Tour before you can rate it.  You can do this from the Tours page"></BlankPage>}
        <IonGrid>
          <IonRow class="starHeader-sm">
            {propertyAddress}
          </IonRow>
          {(propertystars > -1) &&
            <IonRow class="starHeader">
              <StarRating onSetRating={setPropertyRating} id={`po-${propertyid}`} disabled={true} disabledmsg="The overall rating is automaically calculated as you click on the stars below." initalstars={propertystars} key={`ps-${propertyid}-${propertystars}`} />
            </IonRow>
          }
          {pavailable && <Fragment>

            <IonRow class="starHeader">
              <IonLabel>{formatter.format(valuation)}</IonLabel>
            </IonRow>
            <IonRow class="starHeader">
              <IonRange class="rangeslide" color="primary" knob-size="2" dualKnobs={false} min={minValuation} max={maxValuation} value={valuation} step={5000} snaps={false} onIonBlur={valuationBlur} onTouchEnd={valuationBlur} onMouseUp={valuationBlur} onIonChange={e => setRangeValue(e.detail.value as any)} />
            </IonRow>
            <IonRow class="smallText">
              <IonLabel>What Would You Pay?</IonLabel>
            </IonRow>
          </Fragment>
          }
        </IonGrid>

        {pavailable &&
          <IonList class="ion-padding">
            {overallStars.map(star => {
              return (<StarRating onSetRating={setStarRating} id={star.starid} name={star.starname} initalstars={star.starcount} key={`ov-${propertyid}-${star.starid}-${star.starcount}`} />)
            })
            }
          </IonList>
        }

        <IonItem class="no-item-border ion-padding-bottom">
          {pavailable && <IonTextarea key={`ovtxt-${propertyid}`} value={text} onIonBlur={(e) => textblur(e)} placeholder=" Enter your notes here..." class="inpbox" rows={5}></IonTextarea>}
        </IonItem>
      </IonContent>
    </IonPage >
  );
};

export default Overall;
