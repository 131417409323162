import React, { Fragment } from "react";
import { IonAvatar, IonCol, IonGrid, IonIcon, IonImg, IonLabel, IonRow } from '@ionic/react';
import { phonePortraitOutline, mailOutline, chatboxOutline } from 'ionicons/icons';
import { formatPhone } from "../global/helperf";
import './AgentContact.css';

interface ContainerProps {
    brokerdata: any;
}

const AgentContact: React.FC<ContainerProps> = ({ brokerdata }) => {
    return (
        <IonGrid>

            <IonRow class="agent-content">
                <IonCol class="col-right">
                    <a href={`tel:${brokerdata.phone}`}>
                        <IonIcon class="col-right agent-icons" float-right icon={phonePortraitOutline} />
                    </a>
                </IonCol>
                <IonCol class="col-center agent-icons">
                    <a href={`mailto:${brokerdata.email}`}>  <IonIcon icon={mailOutline} /></a>
                </IonCol>
                <IonCol class="agent-icons">
                    <a href={`sms://${brokerdata.phone}`}><IonIcon icon={chatboxOutline} /></a>
                </IonCol>
            </IonRow>
            <IonRow class="agent-content">
                <IonLabel>{brokerdata.firstname} {brokerdata.lastname}</IonLabel>
            </IonRow>
            <IonRow class="agent-content">
                <IonLabel>{formatPhone(brokerdata.phone)}</IonLabel>
            </IonRow>
            <IonRow class="agent-content">
                <IonLabel>{brokerdata.email}</IonLabel>
            </IonRow>
            <IonRow class="agent-content">
                <IonAvatar class="brokeravatar">
                    <IonImg src={brokerdata.img}></IonImg>
                </IonAvatar>
            </IonRow>
            {
                brokerdata.companyid !== "co-homestar" &&
                <Fragment>
                    <IonRow class="agent-content">

                        <IonImg class="agent-logo" src={brokerdata.logo}></IonImg>

                    </IonRow>
                    {/*                     <IonRow class="agent-content">
                        <IonLabel>{brokerdata.address}</IonLabel>
                    </IonRow>
                    <IonRow class="agent-content">
                        <IonLabel>{brokerdata.city}, {brokerdata.state} {brokerdata.postalcode}</IonLabel>
                    </IonRow> */}
                </Fragment>
            }
        </IonGrid>
    )
}

export default AgentContact;