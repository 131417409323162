import {createContext, useReducer, PropsWithChildren, Dispatch} from 'react'

type Context = {state: State; dispatch: Dispatch<Action>}

interface State {
    property: any,
    member: Member,
    signedin: boolean,
    clients: Client[],
    currclient: string | null,
    tourid: string | null,
    clientmode: boolean,
    newproperties: boolean,
    propertyid: string | null,
    path: string | null,
    error: string | null,
}

interface Member {
    id: any,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    companyid: string,
    companyname: string,
    agent: boolean,
    subscriptionlevel: number,
    listingsource: string,
    address: string,
    city: string,
    state: string,
    postalcode: string,
    logo: string,
    primarycolor: string,
    secondarycolor: string,
    starcolor: string,
}

interface Client {
    id: any,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    agentid: string,
    shortlink: string,
    budget: number,
    active: boolean,
}

// Discriminating Union
type Action =
    | SetToursRequestAction
    | DeleteTourRequestAction
    | SetPropertyRequestAction
    | NewPropertiesRequestAction
    | AddClientRequestAction
    | DeleteClientRequestAction
    | UpdateMemberRequestAction
    | UpdatePathRequestAction
    | ResetRequestAction
    | FailRequestAction


interface SetToursRequestAction {
    type: 'SET_TOURS'
    payload: {member: Member, clients: Client[], clientmode: boolean, signedin: boolean}
}

interface DeleteTourRequestAction {
    type: 'DELETE_TOUR'
    payload: {member: Member, clients: Client[], clientmode: boolean, signedin: boolean}
}

interface SetPropertyRequestAction {
    type: 'SET_PROPERTY'
    payload: any
}
interface NewPropertiesRequestAction {
    type: 'NEW_PROPERTIES'
    payload: {newproperties: boolean}
}

interface AddClientRequestAction {
    type: 'ADD_CLIENT'
    payload: {client: Client}
}

interface DeleteClientRequestAction {
    type: 'DELETE_CLIENT'
    payload: {client: Client}
}

interface UpdateMemberRequestAction {
    type: 'UPDATE_MEMBER'
    payload: {
        firstname: string,
        lastname: string,
        phone: string,
        companyid: string,
        listingsource: string
    }
}

interface UpdatePathRequestAction {
    type: 'UPDATE_PATH'
    payload: {path: string}
}

interface ResetRequestAction {
    type: 'RESET'
}
interface FailRequestAction {
    type: 'FAIL_REQUEST'
    payload: any
}
const initialStoreContext: Context = {
    state: {
        property: null,
        member: {
            id: null,
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            companyid: "",
            companyname: "",
            agent: false,
            subscriptionlevel: 0,
            listingsource: "",
            address: "",
            city: "",
            state: "",
            postalcode: "",
            logo: "",
            primarycolor: "",
            secondarycolor: "",
            starcolor: "",
        },
        clients: [],
        currclient: "",
        tourid: null,
        clientmode: false,
        newproperties: false,
        propertyid: null,
        path: null,
        signedin: false,
        error: null
    },
    dispatch: (_a) => {},
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {

        case 'SET_TOURS':
            return {
                ...state,
                member: action.payload.member,
                clients: action.payload.clients,
                clientmode: action.payload.clientmode,
                signedin: action.payload.signedin
            }
        case 'DELETE_TOUR':
            return {
                ...state,
            }
        case 'SET_PROPERTY':
            return {
                ...state,
                property: action.payload.value,
                path: action.payload.path,
                tourid: action.payload.tourid,
                currclient: action.payload.clientid,
            }
        case 'NEW_PROPERTIES':
            return {
                ...state,
                newproperties: action.payload.newproperties,
            }
        case 'ADD_CLIENT':
            state.clients.push(action.payload.client);
            return {
                ...state,
                clients: state.clients,
            }
        case 'DELETE_CLIENT':
            const idx = state.clients.indexOf(action.payload.client);
            if (idx > -1)
                state.clients.splice(idx, 1)
            return {
                ...state,
                clients: state.clients,
            }
        case 'UPDATE_MEMBER':
            state.member.firstname = action.payload.firstname;
            state.member.lastname = action.payload.lastname;
            state.member.phone = action.payload.phone;
            state.member.companyid = action.payload.companyid;
            state.member.listingsource = action.payload.listingsource;
            return {
                ...state,
            }
        case 'UPDATE_PATH':
            state.path = action.payload.path;
            return {
                ...state,
                path: action.payload.path,
            }
        case 'RESET':
            return {
                ...state,
                property: []
            }
        case 'FAIL_REQUEST':
            return {...state, error: action.payload}

        default:
            return assertNever(action)
    }
}

const storeContext = createContext(initialStoreContext)
const {Provider} = storeContext

const StateProvider = ({children}: PropsWithChildren<any>) => {

    const [state, dispatch] = useReducer(reducer, initialStoreContext.state)
    return <Provider value={{state, dispatch}}>{children}</Provider>
}

function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

export {storeContext, StateProvider}