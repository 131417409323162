import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSlides, IonSlide, IonImg, IonButton, IonFooter } from '@ionic/react';
import { storeContext } from '../context/store';
import { useCookies } from "react-cookie";
import { hideTabBar } from '../global/helperf'
import './swiper-bundle.min.css';
import './clientIntro.css';

const ClientIntro: React.FC = () => {
  const history = useHistory();
  const { state } = useContext(storeContext)
  const [buttonText, setButtonText] = useState("Next");
  const [logo, setLogo] = useState("");
  const [cookies, setCookie] = useCookies(['hs-clvisit']);

  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const handleNext = () => {
    slidesRef.current?.slideNext();
    slidesRef.current?.isEnd().then((isLast) => {
      if (buttonText !== "Next") {
        history.push("/tours");
      }
      if (isLast)
        setButtonText(slidesRef.current?.isEnd ? "Let's Go!" : "Next");
    })
  };

  const swiperOptions = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    }
  };
  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    if (cookies && cookies['hs-clvisit']) {
      (async () => {
        await delay(1200);
        history.push("/tours");
      })();
    }
    else {
      let expires = new Date();
      expires.setDate(expires.getDate() + 365)
      setCookie("hs-clvisit", true, { expires: expires, path: '/' });
    }
    hideTabBar();
    setLogo(state.member.logo)
  }, [state.member.logo, state.member.subscriptionlevel, history]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Welcome</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen >
        <IonSlides ref={slidesRef} color="primary" pager={true} options={swiperOptions}>
          <IonSlide>
            <div>
              <IonImg class="slideImg" src={logo}></IonImg>
            </div>
          </IonSlide>

          <IonSlide>
            <div>
              <img src='/assets/adventure_map.svg' className='slideImg' alt="map"></img>
              <p className='introText'>So, you're setting off on your home finding adventure! It's hard to keep everything organized.  So many homes, so many impressions.  We want to help you so you can find the perfect home. For you.</p>
            </div>
          </IonSlide>

          <IonSlide>
            <div>
              <img src='/assets/rate.svg' className='slideImg' alt="rate"></img>
              <p className='introText'>Along the journey we'll help you rate each house, map out your tour, communicate more effectively, and keep all your notes in one, easily accessible place.</p>
            </div>
          </IonSlide>

          <IonSlide>
            <div>
              <img src='/assets/sweet_home.svg' className='slideImg' alt="home"></img>
              <p className='introText'>Let's get started and find that house you have been searching for.  It's out there just waiting for you.</p>
            </div>
          </IonSlide>

        </IonSlides>
      </IonContent>
      <IonFooter no-border>
        <IonButton expand="full" onClick={handleNext}>{buttonText}</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default ClientIntro;