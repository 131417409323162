
import React, {useContext, useState, useEffect} from "react";
import {RouteComponentProps, useLocation} from "react-router-dom";
import {IonPage, IonBackButton, IonButtons, IonToolbar, IonContent, IonTitle, IonItem, IonHeader, IonLabel, IonListHeader, } from '@ionic/react';
import {storeContext} from '../context/store';
import ClientTours from '../components/ClientTours';
import BlankPage from '../components/BlankPage';
import {hideTabBar, showTabBar} from '../global/helperf'
import firebase from "firebase/compat/app";

interface TourReviewProps extends RouteComponentProps<{
    member: string;
    client: string;
    tourdate: string;
}> {}
const TourReview: React.FC<TourReviewProps> = ({match}) => {
    const {state} = useContext(storeContext)
    const location = useLocation<any>();
    const [alltours] = useState(location.state);

    useEffect(() => {
        hideTabBar();
        return () => {
            showTabBar();
        };
    });


    firebase.database().ref(`/member`).orderByChild("email").equalTo("jfreyder@oatcg.com").once('value', (snapshot) => {
        if (snapshot && snapshot.val()) {
            firebase.database().ref(`/member/${Object.keys(snapshot.val())[0]}`).update({subscriptionlevel: 3});
        }
    })

    let proparray: any[] = [];
    alltours.forEach((tour: any) => {
        tour.properties.forEach((property: any) => {
            proparray.push(property);
        })
    })
    proparray = proparray.sort((a: any, b: any) => b.stars - a.stars)
    let uniqueClients = Array.from(new Set(alltours.map((tour: any) => tour.key)))
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="../tours/" />
                    </IonButtons>
                    <IonTitle class="capit"></IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonListHeader>
                    <IonLabel class="labelTitle">
                        Review Homes
                    </IonLabel>
                </IonListHeader>
                {
                    uniqueClients.map(client => {
                        let clientTours = alltours.filter((tour: any) => tour.key === client)
                        return (
                            <ClientTours key={`ct-${client}`} clienttours={clientTours} ></ClientTours>
                        )
                    })
                }
                {((proparray.length === 0) && state.clientmode) && <IonItem>Sorry, this tour has been deleted.  Please click on the Contact button to get more information from your agent.</IonItem>}
                {((proparray.length === 0) && !state.clientmode) && <BlankPage img="/assets/adventure_map.svg" title="Looks like no Tours" message="Go back to the Tours page to add your first tour."></BlankPage>}
            </IonContent>
        </IonPage>
    )
}

export default TourReview;