import React, {useContext, useEffect, useState} from "react";
import firebase from 'firebase/compat/app';
import {useForm} from "react-hook-form";
import {IonButton, IonCol, IonGrid, IonRow} from '@ionic/react';
import {storeContext} from '../context/store';

interface ContainerProps {
    setRegistered: any;
    member: any;
}

const Profile: React.FC<ContainerProps> = ({setRegistered, member}) => {
    const {dispatch} = useContext(storeContext);
    const [companies, setCompanies] = useState<any[]>([]);
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {firstname: member.firstname, lastname: member.lastname, phone: member.phone, companyid: member.companyid, budget: "", listingsource: "redfin", autolist: true}});

    useEffect(() => {
        let tmpcompanies: any = [];
        firebase.database().ref(`/company/`).orderByChild("name").once('value', (snapshot) => {
            snapshot.forEach(record => {
                let tmpComp = record.val()
                tmpComp.key = record.key
                tmpcompanies.push(tmpComp)
            })
        })
            .then(() => {
                setCompanies(tmpcompanies.reverse())
            });
    }, [])

    const styles = {
        input: {
            width: "100%",
        },
    };
    const onSubmit = (memberdata: any) => {
        const listingsource = memberdata.listingsource;
        firebase.database().ref(`/member/${member.id}/`).update({
            firstname: memberdata.firstname,
            lastname: memberdata.lastname,
            phone: memberdata.phone,
            companyid: memberdata.companyid,
            listingsource: listingsource,
        }).then(() => {
            dispatch({
                type: 'UPDATE_MEMBER', payload: {
                    firstname: memberdata.firstname,
                    lastname: memberdata.lastname,
                    phone: memberdata.phone,
                    companyid: memberdata.companyid,
                    listingsource: listingsource
                }
            });
            if (setRegistered) setRegistered(true);
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id="frmUpdateMember">
            <IonGrid>
                <IonRow class="formrow">
                    <IonCol>
                        <input id="firstname" style={styles.input} {...register('firstname', {required: true, maxLength: 40})} placeholder='First Name' />
                        {errors.firstname && errors.firstname.type === "required" && <span> required...</span>}
                        {errors.firstname && errors.firstname.type === "maxLength" && <span>Max length exceeded</span>}
                    </IonCol>
                </IonRow>
                <IonRow class="formrow">
                    <IonCol>
                        <input id="lastname" style={styles.input} {...register('lastname', {required: true, maxLength: 40})} placeholder='Last Name' />
                        {errors.lastname && errors.lastname.type === "required" && <span> required...</span>}
                        {errors.lastname && errors.lastname.type === "maxLength" && <span>Max length exceeded</span>}
                    </IonCol>
                </IonRow>
                <IonRow class="formrow">
                    <IonCol>
                        <input id="phone" style={styles.input} {...register('phone', {required: true, maxLength: 15})} placeholder='Phone' />
                        {errors.phone && errors.phone.type === "required" && <span> required...</span>}
                        {errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span>}
                    </IonCol>
                </IonRow>

                <IonRow class="formrow">
                    <IonCol size="4">Company</IonCol>
                    <IonCol>
                        <select {...register('companyid', {required: true})} >
                            {companies.map((company) => (
                                <option key={company.key} value={company.key}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                        {errors.companyid && errors.companyid.type === "required" && <span> required...</span>}
                    </IonCol>
                </IonRow>

                <IonRow class="formrow">
                    <IonCol size="4">Listing Source</IonCol>
                    <IonCol>
                        <select {...register('listingsource', {required: true})} >
                            <option key='redfin' value='redfin' className="inpbox">
                                Redfin
                            </option>
                            <option key='zillow' value='zillow'>
                                Zillow
                            </option>
                            <option key='realtor' value='realtor'>
                                Realtor
                            </option>
                            <option key='compass' value='compass'>
                                Compass
                            </option>                       </select>
                        {errors.listingsource && errors.listingsource.type === "required" && <span> required...</span>}
                    </IonCol>
                </IonRow>
                <IonRow class="pt-20">
                    <IonCol size="7">
                        <IonButton type="submit" size="small" padding-end>Register</IonButton>
                    </IonCol>
                </IonRow>

            </IonGrid>
        </form>
    )
}

export default Profile;
